import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import UploadPage from './components/UploadPage';
import UpscalerPage from './components/UpscalerPage';
import LoginPage from './components/LoginPage';
import ProfilePage from './components/ProfilePage';
import ImageRestorationPage from './components/ImageRestorationPage';
import SuccessPage from './components/SuccessPage';
import BetaAccessPage from './components/BetaAccessPage'; // Import BetaAccessPage
import '../src/styles/globals.css';
import Cookies from 'js-cookie';
import { ProfileProvider } from './ProfileContext'; // Import ProfileProvider

// ProtectedRoute Component
const ProtectedRoute = ({ children }) => {
  const token = Cookies.get('token'); // Retrieve token from cookie
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const App = () => {
  return (
    <ProfileProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<BetaAccessPage />} /> {/* Set BetaAccessPage as the default */}
          <Route path="/home" element={<LandingPage />} /> {/* Optional: Assign LandingPage to '/home' */}
          <Route path="/beta-access" element={<BetaAccessPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/success" element={<SuccessPage />} />

          {/* Protected Routes */}
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload"
            element={
              <ProtectedRoute>
                <UploadPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upscaler"
            element={
              <ProtectedRoute>
                <UpscalerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/restoration"
            element={
              <ProtectedRoute>
                <ImageRestorationPage />
              </ProtectedRoute>
            }
          />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </ProfileProvider>
  );
};

export default App;
