// pages/CombinedPage.js

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Spin } from "antd";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Lottie from "react-lottie";
import animationData from "../components/Assets/vudoo-logo-animation.json";
import AccountSection from "../components/sharedProfile/AccountSection";
import UsageHistorySection from "../components/sharedProfile/UsageHistorySection";
import PlansSection from "../components/sharedProfile/PlansSection";
import "../styles/ProfilePage.css";
import ZoomControls from "../components/shared/ZoomControls";
import Hotjar  from "@hotjar/browser";

export default function CombinedPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("account");
  const [userCredits, setUserCredits] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [usageHistory, setUsageHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    position: "",
    avatar: "/placeholder.svg?height=64&width=64",
  });

  // State and Handlers for ZoomControls
  const [zoomLevel, setZoomLevel] = useState(100);

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 10, 200));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 10, 50));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  // Fetch user profile data
  const fetchUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) {
      setIsLoggedIn(false);
      setLoading(false);
      return;
    }
    try {
      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/profile", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error("Failed to fetch user profile");
      const data = await response.json();
      const nameParts = data.name ? data.name.split(" ") : ["User"];
      setProfileData({
        firstName: nameParts[0] || "User",
        lastName: nameParts.slice(1).join(" ") || "",
        email: data.email || "",
        avatar: data.photoURL || "/placeholder.svg?height=64&width=64",
        position: data.position || "",
      });
      setUserCredits(data.credits || 0);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setIsLoggedIn(false);
    }
  };

  // Fetch usage history data
  const fetchUsageHistory = async () => {
    const token = Cookies.get("token");
    if (!token) return;
    try {
      const restoreResponse = await fetch(
        "https://www.ek0go8g.vudoo.ai/api/imageRestoreGallery",
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const upscaleResponse = await fetch(
        "https://www.ek0go8g.vudoo.ai/api/imageUpscaleGallery",
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!restoreResponse.ok || !upscaleResponse.ok)
        throw new Error("Failed to fetch usage history");
      const restoreData = await restoreResponse.json();
      const upscaleData = await upscaleResponse.json();
      setUsageHistory([...restoreData.images, ...upscaleData.images]);
    } catch (error) {
      console.error("Error fetching usage history:", error);
    }
  };

  // Handle redirect and update credits after successful checkout
  useEffect(() => {
    const updateCreditsAfterCheckout = async (sessionId) => {
      const token = Cookies.get("token");
      try {
        const response = await fetch(
          `https://www.ek0go8g.vudoo.ai/api/retrieve-credits?session_id=${sessionId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to retrieve credits");
        const data = await response.json();
        setUserCredits(data.updatedCredits || 0);
      } catch (error) {
        console.error("Error retrieving updated credits:", error);
      }
    };

    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("session_id");
    const success = queryParams.get("success");

    if (success === "true" && sessionId) {
      updateCreditsAfterCheckout(sessionId);
    }
  }, [location.search]);

  // Initial data fetch
  useEffect(() => {
    const initialize = async () => {
      await fetchUserProfile();
      await fetchUsageHistory();
      setLoading(false);
    };

    if (location.state && location.state.section) {
      setCurrentPage(location.state.section);
    }

    initialize();
  }, [location.state]);

  // Handle profile updates
  const handleUpdateProfile = async (updatedProfile) => {
    const token = Cookies.get("token");
    if (!token) {
      console.error("User is not authenticated");
      return;
    }

    try {
      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/profile", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedProfile),
      });

      if (!response.ok) throw new Error("Failed to update profile");

      const data = await response.json();
      const nameParts = data.name ? data.name.split(" ") : ["User"];
      setProfileData({
        firstName: nameParts[0] || "User",
        lastName: nameParts.slice(1).join(" ") || "",
        email: data.email || "",
        avatar: data.photoURL || "/placeholder.svg?height=64&width=64",
        position: data.position || "",
      });

      setUserCredits(data.credits || 0);
      localStorage.setItem(
        "profileAvatar",
        data.photoURL || "/placeholder.svg?height=64&width=64"
      );
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#151515] text-white flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#151515] text-white flex flex-col">
      <header className="fixed top-0 left-0 right-0 flex justify-between items-center h-16 px-4 z-50 bg-transparent">
        {/* Overlay Effect that starts from the top of the screen */}
        <div
          className="absolute top-0 left-0 right-0 h-16 z-40"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent)",
            backdropFilter: "blur(1px)",
            WebkitBackdropFilter: "blur(1px)", // For cross-browser compatibility
          }}
        ></div>

        {/* Logo Animation (unaffected by the blur) */}
        <button
          className="flex items-center focus:outline-none relative z-50" // Place on top of the blur effect
          onClick={() => navigate("/home")}
          style={{ backgroundColor: "transparent", border: "none" }}
          aria-label="Go to Home"
        >
          <Lottie options={defaultOptions} height={16} width={"auto"} />
        </button>

        {/* Tokens Left Button (unaffected by the blur) */}
        <div
          className="px-3 py-2 rounded-lg border border-[var(--color-border)] bg-[var(--coal-800)] text-sm cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300 relative z-50" // Place on top of the blur effect
          onClick={() => navigate(isLoggedIn ? "/profile" : "/login")}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              navigate(isLoggedIn ? "/profile" : "/login");
            }
          }}
          aria-label={isLoggedIn ? "Go to Profile" : "Go to Login"}
        >
          {userCredits !== null ? `${userCredits} tokens left` : "Fetching..."}
        </div>
      </header>

      {/* Main Content */}
      <div className="flex w-full max-w-[1360px] mx-auto pt-16">
        <nav className="w-64 p-8">
          {[
            { id: "dashboard", label: "Home" },
            { id: "account", label: "Account" },
            { id: "usage", label: "Usage History" },
            { id: "plans", label: "Plans and Tokens" },
            { id: "billing", label: "Billing" },
          ].map((item) => (
            <button
              key={item.id}
              className={`text-[15px] w-full text-left py-2 ${
                currentPage === item.id ? "text-white" : "text-white/50"
              } hover:text-white transition-colors`}
              onClick={() => {
                if (item.id === "dashboard") {
                  navigate("/home");
                } else if (item.id === "billing") {
                  window.open(
                    "https://billing.stripe.com/p/login/fZe8xe13S8ZJ1zOfYY",
                    "_blank"
                  );
                } else {
                  setCurrentPage(item.id);
                }
              }}
            >
              {item.label}
            </button>
          ))}
        </nav>

        <main className="flex-1 p-8">
          <TransitionGroup>
            {currentPage === "account" && (
              <CSSTransition key="account" timeout={300} classNames="fade">
                <AccountSection
                  profileData={profileData}
                  userCredits={userCredits}
                  onUpdateProfile={handleUpdateProfile}
                />
              </CSSTransition>
            )}
            {currentPage === "usage" && (
              <CSSTransition key="usage" timeout={300} classNames="fade">
                <UsageHistorySection usageHistory={usageHistory} />
              </CSSTransition>
            )}
            {currentPage === "plans" && (
              <CSSTransition key="plans" timeout={300} classNames="fade">
                <PlansSection />
              </CSSTransition>
            )}
          </TransitionGroup>
        </main>
      </div>

      {/* ZoomControls Component */}
      <ZoomControls
        zoomLevel={zoomLevel}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
      />
    </div>
  );
}
