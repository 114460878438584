import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import CustomButton from '../components/shared/CustomButton';
import Gallery from '../components/shared/Gallery';
import SettingsPanel from '../components/shared/SettingsPanel';
import ImageDisplay from '../components/shared/ImageDisplay';
import DownloadSection from '../components/shared/DownloadSection';
import ZoomControls from '../components/shared/ZoomControls';
import SharedLayout from '../components/shared/SharedLayout';
import '../styles/UpscalerPage.css';
import usePreventRightClick from './usePreventRightClick';
import Hotjar from '@hotjar/browser';

const modelOptions = {
  Auto: 'juggernaut_reborn.safetensors [338b85bc4f]',
  Painting: 'impressionismOil_sd15.safetensors [84d76a0328]',
  Cinematic: 'consistentFactor_euclidV61.safetensors',
};

const BASE_RESOLUTION = 1024 * 1024; // 1 MP
const MIN_TOKENS = 1; // Minimum token charge

const UpscalerPage = () => {
  usePreventRightClick();
  const navigate = useNavigate();

  // State Variables
  const [scaleFactor, setScaleFactor] = useState(2);
  const [creativity, setCreativity] = useState(65); // Default frontend slider value is 37
  const [creativityEnabled, setCreativityEnabled] = useState(false);
  const [resemblance, setResemblance] = useState(0.5);
  const [resemblanceEnabled, setResemblanceEnabled] = useState(false);
  const [hdrStrength, setHdrStrength] = useState(0);
  const [hdrStrengthEnabled, setHdrStrengthEnabled] = useState(false);
  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [upscaledImageUrl, setUpscaledImageUrl] = useState('');
  const [isUpscaled, setIsUpscaled] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [isUpscaling, setIsUpscaling] = useState(false);
  const [selectedModel, setSelectedModel] = useState('Auto');
  const [tokensAvailable, setTokensAvailable] = useState(null);
  const [tokensRequired, setTokensRequired] = useState(MIN_TOKENS);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [showInputVariants, setShowInputVariants] = useState(false);
  const [showDownloadSection, setShowDownloadSection] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);

  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedImageState, setSelectedImageState] = useState('');
  const [imageMetadata, setImageMetadata] = useState([]);
  const [selectedMetadata, setSelectedMetadata] = useState({
    width: 0,
    height: 0,
    format: 'Unknown',
    scaleFactor: scaleFactor,
  });

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  const deductCredits = async (creditsCost) => {
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/credits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ action: 'deduct', creditsCost }),
      });

      if (!response.ok) {
        throw new Error('Failed to deduct credits');
      }

      await response.json();
    } catch (error) {
      alert('Failed to deduct credits. Please try again.');
      throw error;
    }
  };

  const fetchUserProfile = useCallback(async () => {
    const token = Cookies.get('token');
    if (!token) return;

    try {
      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/profile', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch user profile');

      const profileData = await response.json();
      setTokensAvailable(profileData.credits || 0);

      if (profileData.credits <= 1) {
        navigate('/profile', { state: { section: 'plans' } });
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      message.error('Failed to fetch user profile.');
    }
  }, [navigate]);

  const calculateTokensRequired = (width, height, scaleFactor) => {
    if (width === 0 || height === 0) {
      console.error('Width or Height is zero, cannot calculate tokens required.');
      return MIN_TOKENS;
    }

    const scaledWidth = width * scaleFactor;
    const scaledHeight = height * scaleFactor;
    const scaledPixels = scaledWidth * scaledHeight;
    const scaledMegapixels = scaledPixels / 1_000_000;
    const tokenCost = Math.ceil(scaledMegapixels);

    return Math.max(tokenCost, MIN_TOKENS);
  };

  // Image Loading and User Profile Fetching
  useEffect(() => {
    fetchUserProfile();

    const imageUrl = sessionStorage.getItem('uploadedImageUrl');
    setOriginalImageUrl(imageUrl);
    setUploadedImageUrl(imageUrl);

    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
        const tokenCost = calculateTokensRequired(img.width, img.height, scaleFactor);
        setTokensRequired(tokenCost);

        const metadata = {
          width: img.width,
          height: img.height,
          format: imageUrl.split('.').pop().split('?')[0].toUpperCase(),
          scaleFactor: scaleFactor,
        };

        setImageMetadata([metadata]);
        setSelectedMetadata(metadata);

        setGalleryImages([imageUrl]);
        if (!isUpscaled) {
          setSelectedImageState(imageUrl);
        }
      };

      img.onerror = (error) => {
        console.error('Image failed to load:', error);
      };
    }
  }, [fetchUserProfile]); // Only runs on mount and when fetchUserProfile changes

  // Recalculate Tokens Required When Scale Factor Changes
  useEffect(() => {
    if (imageDimensions.width && imageDimensions.height) {
      const tokenCost = calculateTokensRequired(imageDimensions.width, imageDimensions.height, scaleFactor);
      setTokensRequired(tokenCost);
    }
  }, [scaleFactor, imageDimensions]);

  // Handle Upscaled Image Addition
  useEffect(() => {
    if (isUpscaled && upscaledImageUrl) {
      setGalleryImages((prevImages) => [...prevImages, upscaledImageUrl]);
      setImageMetadata((prevMetadata) => [
        ...prevMetadata,
        {
          width: imageDimensions.width * scaleFactor,
          height: imageDimensions.height * scaleFactor,
          format: 'JPG',
          scaleFactor: scaleFactor,
        },
      ]);
      setSelectedMetadata({
        width: imageDimensions.width * scaleFactor,
        height: imageDimensions.height * scaleFactor,
        format: 'JPG',
        scaleFactor: scaleFactor,
      });

      setSelectedImageState(upscaledImageUrl); // Automatically select the upscaled image

      fetchUserProfile();
    }
  }, [isUpscaled, upscaledImageUrl, imageDimensions.width, imageDimensions.height, fetchUserProfile]);

  const handleUpscale = async () => {
    const token = Cookies.get('token');
    if (!token) {
      message.error('Please log in first.');
      return;
    }

    if (tokensAvailable <= 1) {
      navigate('/profile', { state: { section: 'plans' } });
      return;
    }

    if (tokensAvailable < tokensRequired) {
      message.error('Not enough tokens. Please purchase more.');
      return;
    }

    setIsUpscaling(true);
    try {
      // Map frontend creativity value (1-100) to backend value (0.01 - 0.52)
      const creativityBackendValue = creativityEnabled ? parseFloat(((creativity / 100) * 0.52).toFixed(2)) : 0.30; // Default backend value is 0.30

      const payload = {
        input_image: originalImageUrl,
        scale_factor: scaleFactor,
        denoising_strength: creativityBackendValue,
        resemblance: resemblanceEnabled ? resemblance : 0.5,
        hdr_strength: hdrStrengthEnabled ? hdrStrength : 0,
        width: imageDimensions.width,
        height: imageDimensions.height,
        model: modelOptions[selectedModel],
      };

      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/upscale', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error('Failed to upscale image');

      const result = await response.json();
      setUpscaledImageUrl(result.upscaledImage);
      setIsUpscaled(true);
      message.success('Image upscaled successfully!');

      await deductCredits(tokensRequired);
    } catch (error) {
      console.error('Upscaling failed:', error);
      message.error('Upscaling failed. Please try again.');
    } finally {
      setIsUpscaling(false);
    }
  };

  const handleDownload = (format) => {
    fetch(upscaledImageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `upscaled_image.${format}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        message.success(`Downloading ${format.toUpperCase()} file...`);
      })
      .catch((error) => {
        console.error('Download failed:', error);
        message.error('Download failed. Please try again.');
      });
  };

  const handleZoomChange = (direction) => {
    setZoomLevel((prevZoom) => {
      const newZoom =
        direction === 'in' ? Math.min(prevZoom + 10, 300) : Math.max(prevZoom - 10, 50);
      return newZoom;
    });
  };

  const handleThumbnailClick = (imageUrl, metadata) => {
    setSelectedImageState(imageUrl);
    setSelectedMetadata(metadata);
  };

  const toggleDragging = () => {
    setIsDraggingEnabled((prev) => !prev);
  };

  const menu = (
    <Menu onClick={(e) => setSelectedModel(e.key)}>
      {Object.keys(modelOptions).map((model) => (
        <Menu.Item key={model}>{model}</Menu.Item>
      ))}
    </Menu>
  );

  const downloadSectionProps = {
    scaleFactor,
    setScaleFactor,
    zoomLevel,
    setZoomLevel,
    handleDownload,
    outputResolution: `${imageDimensions.width * scaleFactor} x ${imageDimensions.height * scaleFactor}`,
    isCollapsed: !showDownloadSection,
    toggleCollapse: () => setShowDownloadSection(!showDownloadSection),
  };

  const headerProps = {
    newAction: 'New Upscale',
    newActionPath: '/upload?type=upscaler',
  };

  const sidebarContent = (
    <>
      <Gallery
        images={galleryImages}
        imageMetadata={imageMetadata}
        onThumbnailClick={handleThumbnailClick}
        isCollapsed={!showInputVariants}
        toggleCollapse={() => setShowInputVariants(!showInputVariants)}
      />

      <SettingsPanel
        settings={{
          scaleFactor,
          outputResolution: `${imageDimensions.width * scaleFactor} x ${imageDimensions.height * scaleFactor}`,
          tokensAvailable,
          tokensRequired,
          options: [
            {
              label: 'Creativity',
              key: 'creativity',
              enabled: creativityEnabled,
              min: 1,
              max: 100,
              step: 1,
              value: creativity,
              labelLeft: '1',
              labelRight: '100',
              tooltip: `Backend Value: ${parseFloat(((creativity / 100) * 0.52).toFixed(2))}`, // Display backend value as a decimal
            },
            {
              label: 'Composition',
              key: 'resemblance',
              enabled: resemblanceEnabled,
              min: 0,
              max: 10,
              step: 1,
              value: resemblance * 10,
              labelLeft: 'Hallucinate',
              labelRight: 'Strict',
            },
            {
              label: 'HDR',
              key: 'hdrStrength',
              enabled: hdrStrengthEnabled,
              min: 1,
              max: 10,
              step: 1,
              value: hdrStrength,
              labelLeft: '1',
              labelRight: '10',
            },
          ],
          modelDropdown: (
            <Dropdown overlay={menu} trigger={['click']}>
              <DownOutlined className="cursor-pointer text-gray-400" />
            </Dropdown>
          ),
          actionButton: (
            <CustomButton onClick={handleUpscale} disabled={isUpscaling}>
              {isUpscaling ? 'Upscaling...' : 'Upscale'}
            </CustomButton>
          ),
        }}
        onToggle={(key, value) => {
          if (key === 'scaleFactor') setScaleFactor(value);
          else if (key === 'creativity') setCreativityEnabled(value);
          else if (key === 'resemblance') setResemblanceEnabled(value);
          else if (key === 'hdrStrength') setHdrStrengthEnabled(value);
        }}
        onChange={(key, value) => {
          if (key === 'creativity') setCreativity(value);
          else if (key === 'resemblance') setResemblance(value / 10);
          else if (key === 'hdrStrength') setHdrStrength(value);
        }}
        isUpscaler={true}
      />

      {isUpscaled && <DownloadSection {...downloadSectionProps} />}
    </>
  );

  const zoomControlsProps = {
    zoomLevel,
    onZoomIn: () => setZoomLevel((prev) => Math.min(prev + 10, 300)),
    onZoomOut: () => setZoomLevel((prev) => Math.max(prev - 10, 50)),
    isDraggingEnabled,
    onToggleDragging: toggleDragging,
  };

  return (
    <SharedLayout
      headerProps={headerProps}
      sidebarContent={sidebarContent}
      zoomControlsProps={zoomControlsProps}
    >
      <ImageDisplay
        uploadedImageUrl={originalImageUrl}
        selectedImageUrl={selectedImageState}
        isProcessing={isUpscaling}
        isProcessed={isUpscaled || selectedImageState !== originalImageUrl}
        zoomLevel={zoomLevel}
        isDraggingEnabled={isDraggingEnabled}
        upscaledImageUrl={upscaledImageUrl}
        processType="upscaling"
      />
    </SharedLayout>
  );
};

export default UpscalerPage;
