import React, { useState, useEffect, useRef } from 'react';
import ImageSlider from '../ImageSlider';
import Lottie from 'react-lottie';
import lottieAnimation from '../Assets/loading-animation.json';
import PropTypes from 'prop-types';
import '../../styles/globals.css';

const ImageDisplay = ({
  uploadedImageUrl,
  selectedImageUrl,
  isProcessing,
  isProcessed,
  zoomLevel = 100,
  onZoomIn,
  onZoomOut,
  isDraggingEnabled,
  upscaledImageUrl,
  showBadges = true,
  variants = [],
  processType = null, // New prop to differentiate pages
}) => {
  // State Management
  const [imageDimensions, setImageDimensions] = useState({ width: 968, height: 544 });
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState(null);

  // Refs for drag functionality
  const dragStart = useRef({ x: 0, y: 0 });
  const containerRef = useRef(null);

  // Lottie Animation Options
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // Effect to handle image loading state and set image dimensions
  useEffect(() => {
    if (uploadedImageUrl) {
      const img = new Image();
      img.src = uploadedImageUrl;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        let newWidth = 968;
        let newHeight = newWidth / aspectRatio;

        if (newHeight > 544) {
          newHeight = 544;
          newWidth = newHeight * aspectRatio;
        }

        setImageDimensions({ width: newWidth, height: newHeight });
        setPosition({ x: 0, y: 0 });
        setIsImageLoading(false);
      };
      img.onerror = () => setIsImageLoading(false); // Handle error case
    }
  }, [uploadedImageUrl]);

  // Effect to set selectedVariant to the first variant if available
  useEffect(() => {
    if (variants.length > 0 && !selectedVariant) {
      setSelectedVariant(variants[0]);
    }
  }, [variants, selectedVariant]);

  // Drag Handlers with Boundary Restrictions Removed
  const handleMouseDown = (e) => {
    if (!isDraggingEnabled) return;
    e.preventDefault();
    setIsDragging(true);
    dragStart.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    };
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !isDraggingEnabled) return;
    const newX = e.clientX - dragStart.current.x;
    const newY = e.clientY - dragStart.current.y;
    setPosition({ x: newX, y: newY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    if (!isDraggingEnabled) return;
    setIsDragging(true);
    const touch = e.touches[0];
    dragStart.current = {
      x: touch.clientX - position.x,
      y: touch.clientY - position.y,
    };
  };

  const handleTouchMove = (e) => {
    if (!isDragging || !isDraggingEnabled) return;
    const touch = e.touches[0];
    const newX = touch.clientX - dragStart.current.x;
    const newY = touch.clientY - dragStart.current.y;
    setPosition({ x: newX, y: newY });
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  // Effect to add/remove event listeners based on dragging state
  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleTouchEnd);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    }

    // Cleanup on unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isDragging, isDraggingEnabled]);

  // Calculate scale based on current zoom
  const scale = zoomLevel / 100;

  return (
    <div className="flex-1 flex flex-col">
      {/* Image Container */}
      <div className="flex-1 flex justify-center items-center relative">
        {/* Fixed Parent Container */}
        <div
          ref={containerRef}
          className="relative overflow-hidden flex justify-center items-center"
          style={{
            width: 'calc(100%-424px)',
            height: 'calc(100vh - 160px)', // Adjust as needed
            cursor: isDraggingEnabled ? (isDragging ? 'grabbing' : 'grab') : 'default',
            transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
            transformOrigin: 'center',
            transition: isDragging ? 'none' : 'transform 0.3s ease-in-out',
            position: 'relative',
            overflow: 'hidden',
          }}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
        >
          {/* Scalable Content Container */}
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <img
              src={uploadedImageUrl}
              alt="Original"
              className="object-contain w-full h-full"
              draggable="false"
            />

            {/* ImageSlider for comparison if processed, dragging is disabled, and image is not loading */}
            {isProcessed && !isImageLoading && !isDraggingEnabled && (
              <ImageSlider
                originalUrl={uploadedImageUrl}
                comparisonUrl={selectedVariant?.imageUrl || selectedImageUrl || upscaledImageUrl}
                uploadedImageWidth={imageDimensions.width} // Use image dimensions
                uploadedImageHeight={imageDimensions.height} // Use image dimensions
                scale={scale}
                containerRef={containerRef}
              />
            )}
          </div>

          {/* Processing Overlay */}
          {isProcessing && (
            <div
              className="absolute inset-0 flex flex-col justify-center items-center backdrop-blur-sm"
              style={{ zIndex: 10, overflow: 'visible', padding: '20px' }}
            >
              <Lottie options={lottieOptions} height={78} width={78} />
              {/* Conditional Text */}
              {processType && (
                <div className="text-center text-white text-[32px] h2 text-lg font-normal']">
                  {processType === 'upscaling' ? 'Upscaling...' : 'Restoring...'}
                </div>
              )}
            </div>
          )}

          {/* Badges */}
          {showBadges && isProcessed && !isProcessing && !isDraggingEnabled && !isImageLoading && (
            <div className="absolute inset-0 pointer-events-none" style={{ zIndex: 20 }}>
              <div className="badge absolute bottom-2 left-2 bg-black/50 text-white px-2 py-1 rounded text-sm">
                BEFORE
              </div>
              <div className="badge absolute bottom-2 right-2 bg-black/50 text-white px-2 py-1 rounded text-sm">
                {selectedVariant?.name || 'AFTER'}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Variants Selector */}
      {variants.length > 0 && (
        <div className="flex justify-center mt-4 space-x-2">
          {variants.map((variant, index) => (
            <button
              key={index}
              onClick={() => setSelectedVariant(variant)}
              className={`px-4 py-2 rounded ${selectedVariant === variant ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
            >
              {variant.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

// PropTypes for validation
ImageDisplay.propTypes = {
  uploadedImageUrl: PropTypes.string.isRequired,
  selectedImageUrl: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired,
  isProcessed: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  isDraggingEnabled: PropTypes.bool.isRequired,
  upscaledImageUrl: PropTypes.string,
  showBadges: PropTypes.bool,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ),
  processType: PropTypes.oneOf(['upscaling', 'restoring']),
};

export default ImageDisplay;
