// src/components/AccountSection.js

import React, { useState, useContext, useEffect } from 'react';
import InputField from './InputField';
import Cookies from 'js-cookie';
import { ProfileContext } from '../../ProfileContext'; // Adjust the path as necessary
import { message } from 'antd';
import PropTypes from 'prop-types';

const AccountSection = ({ profileData, userCredits, onUpdateProfile }) => {
  const { setAvatar: setContextAvatar } = useContext(ProfileContext); // Get setAvatar from context
  const storedAvatar = localStorage.getItem('profileAvatar') || profileData.avatar;
  const storedPosition = localStorage.getItem('profilePosition') || profileData.position;

  const [selectedPosition, setSelectedPosition] = useState(storedPosition);
  const [avatar, setAvatar] = useState(storedAvatar);
  const [isUploading, setIsUploading] = useState(false);
  const [firstName, setFirstName] = useState(profileData.firstName);
  const [lastName, setLastName] = useState(profileData.lastName);

  const positions = [
    'Graphic Designer',
    'Web Designer',
    'UI/UX Designer',
    'Photographer',
    'Videographer',
    'Illustrator',
    'Motion Designer',
    'Product Designer',
    'Art Director',
    'Fashion Photographer',
  ];

  // Update local state when profileData changes (e.g., after fetching or saving)
  useEffect(() => {
    setFirstName(profileData.firstName);
    setLastName(profileData.lastName);
  }, [profileData.firstName, profileData.lastName]);

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
      };

      img.onload = () => {
        const canvas = document.createElement('canvas');
        let { width, height } = img;

        const maxDimension = 200;
        if (width > height) {
          if (width > maxDimension) {
            height = Math.round((height *= maxDimension / width));
            width = maxDimension;
          }
        } else {
          if (height > maxDimension) {
            width = Math.round((width *= maxDimension / height));
            height = maxDimension;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.9);
        resolve(resizedDataUrl);
      };

      img.onerror = (error) => reject(error);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        alert('File size exceeds 5MB.');
        return;
      }

      try {
        const resizedImage = await resizeImage(file);
        setAvatar(resizedImage);
      } catch (error) {
        console.error('Error resizing image:', error);
        alert('Failed to resize the image. Please try a different file.');
      }
    }
  };

  const handleCancel = () => {
    setSelectedPosition(storedPosition);
    setAvatar(storedAvatar);
    setFirstName(profileData.firstName);
    setLastName(profileData.lastName);
  };

  const handleSave = async () => {
    setIsUploading(true);
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('User not authenticated');
      }
  
      // Prepare update data for server
      const updateData = {};
      let hasServerChanges = false;
  
      // Handle Position Update (Local Storage Only)
      if (selectedPosition !== storedPosition) {
        localStorage.setItem('profilePosition', selectedPosition);
      }
  
      // Handle Avatar Update (Server Update)
      if (avatar !== storedAvatar) {
        updateData.avatar = avatar;
        updateData.type = 'updateProfilePicture'; // Optional: Remove if not needed
        hasServerChanges = true;
      }
  
      // Handle First Name and Last Name Update (Server Update)
      if (firstName !== profileData.firstName || lastName !== profileData.lastName) {
        updateData.firstName = firstName;
        updateData.lastName = lastName;
        hasServerChanges = true;
      }
  
      // If there are changes to send to the server
      if (hasServerChanges) {
        const response = await fetch('https://www.ek0go8g.vudoo.ai/api/profile', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updateData), // Ensure updateData is valid JSON
        });
  
        let data;
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          data = await response.json();
        } else {
          const text = await response.text();
          throw new Error(`Server responded with non-JSON data: ${text}`);
        }
  
        if (response.ok) {
          const updatedProfile = { ...profileData };
          if (updateData.avatar) {
            updatedProfile.photoURL = data.photoURL;
            updatedProfile.avatar = data.photoURL;
            localStorage.setItem('profileAvatar', data.photoURL);
            setContextAvatar(data.photoURL); // Update Context with new avatar
          }
          if (updateData.firstName) {
            updatedProfile.firstName = data.firstName;
          }
          if (updateData.lastName) {
            updatedProfile.lastName = data.lastName;
          }
          onUpdateProfile(updatedProfile);
          message.success('Profile updated successfully!');
        } else {
          console.error('Error updating profile:', data);
          alert(`Error updating profile: ${data.details || data}`);
        }
      } else {
      }
    } catch (error) {
      console.error('Error saving profile:', error);
      alert(`Error saving profile: ${error.message}`);
    } finally {
      setIsUploading(false);
    }
  };
  

  return (
    <div className="flex grow items-start gap-24 border-t border-white/15">
      <div className="flex flex-col gap-6 w-[500px]">
        <div className="py-4">
          <h2 className="text-white text-[15px]">Account data</h2>
        </div>

        <div className="flex items-center gap-5">
          <img className="w-16 h-16 rounded-full object-cover" src={avatar} alt="Profile" />
          <div className="flex flex-col gap-1.5">
            <button
              onClick={() => document.getElementById('fileInput').click()}
              className="self-start h-7 w-auto px-2 bg-[#d5d5d5] text-black text-sm rounded hover:opacity-80  transition-colors"
            >
              Upload new
            </button>
            <input
              type="file"
              id="fileInput"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
            <p className="text-white/50 text-[13px]">Min. 200x200 px. PNG or JPG</p>
          </div>
        </div>

        <InputField label="First name" value={profileData.firstName} />
        <InputField label="Last name" value={profileData.lastName} />
        <InputField
          label="Email"
          value={profileData.email}
          disabled={true}
          helperText={
            <span>
              To change your email, please contact{' '}
              <a href="mailto:support@vudoo.ai" className="text-blue-600 underline">
                support
              </a>
            </span>
          }
        />

        <div className="space-y-2">
          <label className="text-white text-sm">Your position</label>
          <select
            value={selectedPosition}
            onChange={(e) => setSelectedPosition(e.target.value)}
            className="w-full h-10 px-3 py-1 bg-white/10 rounded text-[15px] text-white"
          >
            <option value="" disabled>
              Select your position
            </option>
            {positions.map((position) => (
              <option key={position} value={position} className="bg-[#212121] text-white">
                {position}
              </option>
            ))}
          </select>
        </div>

        <div className="flex gap-2 pt-6">
          <button
            onClick={handleSave}
            disabled={isUploading}
            className="px-3 py-2 bg-[#d5d5d5] text-black rounded hover:bg-[#c4c4c4] transition-colors hover:opacity-80"
          >
            {isUploading ? 'Saving...' : 'Save'}
          </button>
          <button
            onClick={handleCancel}
            disabled={isUploading}
            className="px-3 py-2 border border-white/20 text-[#d5d5d5] rounded hover:opacity-90  hover:text-white transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>

      <div className="w-[400px] py-14 bg-[#212121] rounded-lg border border-white/20 flex flex-col justify-center items-center mt-18">
        <div className="text-center text-white text-lg font-normal">You have {userCredits} tokens left</div>
      </div>
    </div>
  );
};

AccountSection.propTypes = {
  profileData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    position: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  userCredits: PropTypes.number.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
};

export default AccountSection;
