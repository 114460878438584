import React from 'react';

const ToggleSwitch = ({ enabled, onToggle, variant = 'default' }) => {
  // Define styles for different variants
  const getBackgroundColor = () => {
    if (variant === 'subscription') {
      return enabled ? 'bg-[#5af4a6]' : 'bg-white/20';
    }
    if (variant === 'settings') {
      return enabled ? 'bg-white/20' : 'bg-black';
    }
    return enabled ? 'bg-[#5af4a6]' : 'bg-gray-600';
  };

  const getKnobColor = () => {
    if (variant === 'subscription') {
      return enabled ? 'bg-[#151515]' : 'bg-white';
    }
    if (variant === 'settings') {
      return enabled ? 'bg-white' : 'bg-white opacity-40';
    }
    return enabled ? 'bg-[#151515]' : 'bg-white';
  };

  return (
    <div
      className={`w-8 h-5 relative rounded-full cursor-pointer transition-colors duration-300 ${getBackgroundColor()}`}
      onClick={() => onToggle(!enabled)}
    >
      <div
        className={`w-3 h-3 absolute top-[4px] rounded-full transition-transform duration-300 ${
          enabled ? 'translate-x-[18px]' : 'translate-x-[2px]'
        } ${getKnobColor()}`}
      />
    </div>
  );
};

export default ToggleSwitch;
