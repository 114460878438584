// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

let auth;

const initializeFirebase = async () => {
  try {
    // Fetch Firebase configuration from your backend
    const response = await fetch('https://www.ek0go8g.vudoo.ai/api/firebaseConfig');
    if (!response.ok) {
      throw new Error('Failed to fetch Firebase configuration');
    }
    const encodedConfig = await response.json();
    
    // Decode configuration
    const decode = (str) => atob(str);
    const decodeConfig = (encodedConfig) => ({
      apiKey: decode(encodedConfig.apiKey),
      authDomain: decode(encodedConfig.authDomain),
      projectId: decode(encodedConfig.projectId),
      storageBucket: decode(encodedConfig.storageBucket),
      messagingSenderId: decode(encodedConfig.messagingSenderId),
      appId: decode(encodedConfig.appId),
    });
    
    const firebaseConfig = decodeConfig(encodedConfig);
    
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    auth = getAuth(app);
    
    // Set persistence to local
    await setPersistence(auth, browserLocalPersistence);
    
    console.log('Firebase initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase:', error);
  }
};

// Ensure Firebase is initialized before using
initializeFirebase();

export { auth, initializeFirebase };
