import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/GalleryItem.css'; // Ensure the path is correct

const GalleryItem = ({ image, onClick }) => {
  const getValidDate = (dateString) => {
    if (!dateString) return 'Unknown Date';
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Unknown Date' : date.toLocaleDateString();
  };

  const uploadDate = image.uploadDate ? getValidDate(image.uploadDate) : 'Unknown Date';
  const resolution = image.resolution || 'Unknown Resolution';
  const toolUsed = image.toolUsed || 'Unknown Tool';

  const handleClick = () => {
    // Save image data to sessionStorage
    sessionStorage.setItem('selectedImage', JSON.stringify(image));
    // Trigger the onClick handler passed from the parent (if exists)
    if (onClick) onClick(image);
  };

  return (
    <div
      className="gallery-item cursor-pointer relative group"
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleClick();
      }}
      aria-label={`View details for Image processed with ${toolUsed}`}
    >
      <img
        src={image.url}
        alt={`Processed with ${toolUsed}`}
        className="gallery-item-image"
        loading="lazy"
      />
      <div className="gallery-item-overlay">
        <div>Processed On: {uploadDate}</div>
        <div>Tool Used: {toolUsed}</div>
      </div>
    </div>
  );
};

// Update the onClick prop to be optional
GalleryItem.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    uploadDate: PropTypes.string.isRequired,
    resolution: PropTypes.string.isRequired,
    toolUsed: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func, 
};

export default GalleryItem;
