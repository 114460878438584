// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/GalleryItem.css */

.gallery-item {
    align-self: start;
    height: auto;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .gallery-item-image {
    width: 100%;
    object-fit: contain; /* Maintain aspect ratio, fit within container */
  }
  
  .gallery-item-overlay {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: var(--coal-900);
    color: var(--color-w-70);
    padding: 2px 6px;
    border: 1px solid var(--color-border);;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;   
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover .gallery-item-overlay {
    opacity: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/GalleryItem.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;IACI,iBAAiB;IACjB,YAAY;IACZ,2CAA2C;IAC3C,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,mBAAmB,EAAE,gDAAgD;EACvE;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,iCAAiC;IACjC,wBAAwB;IACxB,gBAAgB;IAChB,qCAAqC;IACrC,kBAAkB;IAClB,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;IACrB,UAAU;IACV,6BAA6B;EAC/B;;EAEA;IACE,UAAU;EACZ","sourcesContent":["/* src/styles/GalleryItem.css */\n\n.gallery-item {\n    align-self: start;\n    height: auto;\n    border: 1px solid rgba(255, 255, 255, 0.15);\n    border-radius: 0.5rem;\n    overflow: hidden;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n  \n  .gallery-item-image {\n    width: 100%;\n    object-fit: contain; /* Maintain aspect ratio, fit within container */\n  }\n  \n  .gallery-item-overlay {\n    position: absolute;\n    top: 8px;\n    right: 8px;\n    background-color: var(--coal-900);\n    color: var(--color-w-70);\n    padding: 2px 6px;\n    border: 1px solid var(--color-border);;\n    border-radius: 4px;\n    font-family: 'Inter', sans-serif;\n    font-size: 10px;\n    font-weight: 600;\n    line-height: 14px;\n    text-transform: uppercase;\n    letter-spacing: 0.5px;   \n    opacity: 0;\n    transition: opacity 0.3s ease;\n  }\n  \n  .gallery-item:hover .gallery-item-overlay {\n    opacity: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
