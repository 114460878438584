import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const Header = ({ newAction, newActionPath }) => {
  const navigate = useNavigate();

  return (
    <header className="flex justify-between items-center fixed left-4 top-4 z-10">
      <div className="flex items-center gap-2">
        {/* Home Button */}
        <div
          className="w-10 h-10 bg-[#212121] rounded-lg flex items-center justify-center border border-white/20 cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300"
          onClick={() => navigate('/home')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            className="transition-colors duration-300"
          >
            <path
              stroke="var(--white)" // Keep the SVG stroke color constant
              strokeWidth="1.5"
              d="M2.415 8.711a2.25 2.25 0 0 1 1.024-1.886l5.333-3.467a2.25 2.25 0 0 1 2.453 0l5.333 3.467a2.25 2.25 0 0 1 1.024 1.886v6.79c0 .69-.56 1.25-1.25 1.25h-3.667a.25.25 0 0 1-.25-.25v-3a1.75 1.75 0 0 0-1.75-1.75H9.332a1.75 1.75 0 0 0-1.75 1.75v3a.25.25 0 0 1-.25.25H3.665c-.69 0-1.25-.56-1.25-1.25V8.71Z"
            />
          </svg>
        </div>

        {/* New Action Button */}
        <div
          className="text-15 h-10 pr-4 pl-3.5 bg-[#212121] rounded-lg border border-white/20 justify-start items-center gap-2 inline-flex cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300"
          onClick={() => navigate(newActionPath)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className="transition-colors duration-300"
          >
            <path
              stroke="var(--white)" // Keep the SVG stroke color constant
              strokeWidth="1.5"
              d="M8 2v6m0 6V8m0 0h6M8 8H2"
            />
          </svg>
          <div className="">{newAction}</div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  newAction: PropTypes.string.isRequired,
  newActionPath: PropTypes.string.isRequired,
};

export default Header;
