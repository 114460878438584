import React from 'react';

const TooltipCard = ({ title, items = [] }) => {
  return (
    <div className="w-[260px] px-4 py-3 bg-[#212121] rounded-lg border border-white/10 flex flex-col justify-start items-start gap-4">
      <div className="text-white text-sm font-normal leading-tight">{title}</div>
      {/* Map through items only if it’s an array */}
      {items.length > 0 ? (
        items.map((item, index) => (
          <div key={index} className="flex-col justify-start items-start gap-0.5 flex">
            <div className="text-white/50 text-sm font-normal leading-tight">
              Input of {item.input}
            </div>
            <div className="w-full h-5 justify-start items-start gap-4 inline-flex">
              <div className="text-white text-sm font-normal leading-tight">
                {item.images} images to {item.output}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-white/50 text-sm font-normal leading-tight">No data available</div>
      )}
    </div>
  );
};

export default TooltipCard;
