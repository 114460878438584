// ImageRestorationPage.jsx

"use client";

import React, { useState, useEffect, useCallback } from 'react';
import { message } from 'antd';
import Cookies from 'js-cookie';
import CustomButton from '../components/shared/CustomButton';
import Gallery from '../components/shared/Gallery';
import SettingsPanel from '../components/shared/SettingsPanel';
import ImageDisplay from '../components/shared/ImageDisplay';
import DownloadSection from '../components/shared/DownloadSection';
import ZoomControls from '../components/shared/ZoomControls';
import SharedLayout from '../components/shared/SharedLayout';
import '../styles/UpscalerPage.css';
import usePreventRightClick from './usePreventRightClick';
import Hotjar from '@hotjar/browser';

const ImageRestorationPage = () => {
  usePreventRightClick();

 // Hotjar Integration
 useEffect(() => {
  Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
}, []);


  // State Variables
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [restoredImageUrl, setRestoredImageUrl] = useState('');
  const [isRestoring, setIsRestoring] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageMetadata, setImageMetadata] = useState([]);
  const [selectedImage, setSelectedImage] = useState(''); // Selected image from the gallery
  const [selectedMetadata, setSelectedMetadata] = useState({
    width: 0,
    height: 0,
    format: 'Unknown',
    scaleFactor: 2, // Fixed scale factor for restoration
  });
  const [zoomLevel, setZoomLevel] = useState(100);
  const [isGalleryCollapsed, setIsGalleryCollapsed] = useState(true);
  const [isDownloadSectionCollapsed, setIsDownloadSectionCollapsed] = useState(true);
  const [tokensAvailable, setTokensAvailable] = useState(null);
  const [selectedImageState, setSelectedImageState] = useState(null);

  const tokensRequired = 2; // Tokens needed for image restoration

  // Deduct Credits Function
  const deductCredits = async (creditsCost) => {
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/credits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ action: 'deduct', creditsCost }),
      });

      if (!response.ok) {
        throw new Error('Failed to deduct credits');
      }

      await response.json();
    } catch (error) {
      message.error('Failed to deduct credits. Please try again.');
      throw error;
    }
  };

  // Fetch user profile for token information
  const fetchUserProfile = useCallback(async () => {
    const token = Cookies.get('token');
    if (!token) return;

    try {
      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/profile', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch user profile');

      const profileData = await response.json();
      setTokensAvailable(profileData.credits || 0);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      message.error('Failed to fetch user profile.');
    }
  }, []);

  // Initialize component
  useEffect(() => {
    fetchUserProfile();

    // Retrieve the uploaded image from session storage
    const imageUrl = sessionStorage.getItem('uploadedImageUrl');
    if (imageUrl) {
      setUploadedImageUrl(imageUrl);
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        const metadata = {
          width: img.width,
          height: img.height,
          format: imageUrl.split('.').pop().toUpperCase(),
          scaleFactor: 2, // Fixed scale factor for restoration
        };
        setImageMetadata((prevMetadata) => [...prevMetadata, metadata]);
        setSelectedMetadata(metadata);
      };
      setGalleryImages([imageUrl]);
      setSelectedImage(imageUrl); // Initially, the selected image is the uploaded image
      setSelectedImageState(imageUrl); // Initialize selectedImageState
    }
  }, [fetchUserProfile]);

  // Handle the restoration process
  const handleRestoreImage = async () => {
    const token = Cookies.get('token');
    if (!token) {
      message.error('Please log in first.');
      return;
    }

    if (tokensAvailable < tokensRequired) {
      message.error('Not enough tokens. Please purchase more.');
      return;
    }

    if (!uploadedImageUrl) {
      message.error('Please upload an image first.');
      return;
    }

    setIsRestoring(true);
    try {
      const response = await fetch(uploadedImageUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch the uploaded image');
      }
      const blob = await response.blob();

      const formData = new FormData();
      formData.append('file', blob, 'restored_image.jpg');
      formData.append('tokensSpent', tokensRequired); // Send tokens information in the payload

      const result = await fetch('https://www.ek0go8g.vudoo.ai/api/imageRestoration', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!result.ok) {
        throw new Error(`Failed to restore the image. Status: ${result.status}`);
      }

      const data = await result.json();

      if (data.cloudinaryUrl) {
        const restoredUrl = data.cloudinaryUrl;
        setRestoredImageUrl(restoredUrl);
        setGalleryImages((prevGallery) => [...prevGallery, restoredUrl]);

        // Fetch metadata for the restored image
        const img = new Image();
        img.src = restoredUrl;
        img.onload = async () => {
          const metadata = {
            width: img.width,
            height: img.height,
            format: restoredUrl.split('.').pop().toUpperCase(),
            scaleFactor: 2, // Fixed scale factor for restoration
          };
          setImageMetadata((prevMetadata) => [...prevMetadata, metadata]);

          // Deduct credits from available tokens after successful restoration
          await deductCredits(tokensRequired);

          // Fetch the updated user profile to get the latest token count
          await fetchUserProfile();

          message.success('Image restored successfully!');
          setSelectedMetadata(metadata);
          setSelectedImageState(restoredUrl); // Automatically select the restored image

          // Automatically expand the DownloadSection after restoration
          setIsDownloadSectionCollapsed(false);
        };
      } else {
        throw new Error('Restored image URL not found in the response.');
      }
    } catch (error) {
      console.error('Error restoring image:', error);
      message.error('Restoration failed. Please try again.');
    } finally {
      setIsRestoring(false);
    }
  };

  // Handle image download
  const handleDownload = (format) => {
    fetch(restoredImageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `restored_image.${format}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        message.success(`Downloading ${format.toUpperCase()} file...`);
      })
      .catch((error) => {
        console.error('Download failed:', error);
        message.error('Download failed. Please try again.');
      });
  };

  const headerProps = {
    newAction: 'New Restoration',
    newActionPath: '/upload?type=restoration',
  };

  const sidebarContent = (
    <>
      <Gallery
        images={galleryImages}
        imageMetadata={imageMetadata}
        onThumbnailClick={(imageUrl, metadata) => {
          setSelectedImage(imageUrl); // Update selected image
          setSelectedMetadata(metadata);
          setSelectedImageState(imageUrl); // Update selectedImageState
        }}
        isCollapsed={isGalleryCollapsed}
        toggleCollapse={() => setIsGalleryCollapsed(!isGalleryCollapsed)}
      />

      <SettingsPanel
        settings={{
          scaleFactor: null, // Not applicable for restoration
          outputResolution:
            imageMetadata.length > 0
              ? `${imageMetadata[0].width} x ${imageMetadata[0].height}`
              : 'Loading',
          tokensAvailable,
          tokensRequired,
          options: [], // No additional options for restoration
          modelDropdown: null, // Not applicable for restoration
          actionButton: (
            <CustomButton onClick={handleRestoreImage} disabled={isRestoring}>
              {isRestoring ? 'Restoring...' : 'Restore Image'}
            </CustomButton>
          ),
        }}
        onToggle={() => {}}
        onChange={() => {}}
        isRestoration={true} // Enables restoration mode
      />

      {/* Download Section - Always Rendered, Controlled via isDownloadSectionCollapsed */}
      <DownloadSection
        handleDownload={handleDownload}
        outputResolution={
          imageMetadata.length > 0
            ? `${imageMetadata[imageMetadata.length - 1].width} x ${imageMetadata[imageMetadata.length - 1].height}`
            : 'Loading'
        }
        isCollapsed={isDownloadSectionCollapsed}
        toggleCollapse={() =>
          setIsDownloadSectionCollapsed(!isDownloadSectionCollapsed)
        }
      />
    </>
  );

  const zoomControlsProps = {
    zoomLevel,
    onZoomIn: () => setZoomLevel((prev) => Math.min(prev + 10, 300)),
    onZoomOut: () => setZoomLevel((prev) => Math.max(prev - 10, 50)),
  };

  return (
    <SharedLayout
      headerProps={headerProps}
      sidebarContent={sidebarContent}
      zoomControlsProps={zoomControlsProps}
    >
      <ImageDisplay
        uploadedImageUrl={uploadedImageUrl} // Always compare to the original uploaded image
        selectedImageUrl={selectedImageState} // Currently selected image from the gallery or restored image
        isProcessing={isRestoring} // Processing state for restoration
        isProcessed={!!restoredImageUrl || selectedImageState !== uploadedImageUrl} // Processed if a restored image is available or selected image differs from the original
        zoomLevel={zoomLevel}
        isDraggingEnabled={true} // Enable dragging for consistency
        upscaledImageUrl={restoredImageUrl} 
        processType="restoring"
      />
    </SharedLayout>
  );
};

export default ImageRestorationPage;
