// utils/stripe.js
import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

export const getStripe = () => {
  // Debugging logs for clarity
  console.log("Stripe Publishable Key Loaded in Stripe.js (Environment):", process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
  
  // Option 1: Using environment variable directly
  if (!stripePromise && process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY) {
    stripePromise = loadStripe(`${process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);
    console.log("Using environment variable key.");
  } else if (!stripePromise) {
    const hardcodedKey = 'pk_test_51Q2KDqRutoVG5e0388sWpKXO4eAW5s2o1a4gJ1goGLvhgJhUJHoxPNRs0GCUVy4rON4zcAf0gz0DgB2Upojp8kNC000UWoLban'; // Replace with your hardcoded key
    console.warn("Environment variable not found. Falling back to hardcoded key.");
    stripePromise = loadStripe(hardcodedKey);
  }

  return stripePromise;
};
