// UpscalerTooltip.jsx
import React from 'react';
import PropTypes from 'prop-types';

const UpscalerTooltip = ({ text, style }) => {
  console.log('Rendering UpscalerTooltip with text:', text);
  return (
    <div
      className="w-[300px] px-4 pt-3 pb-5 bg-sidebarBackground rounded-lg border border-white/15 flex flex-col justify-center items-start font-sans text-white text-sm leading-tight"
      style={style} // Apply dynamic positioning styles
      role="tooltip" // Enhance accessibility
      aria-label={text} // Provide descriptive text for screen readers
    >
      {/* Dynamic Text Content */}
      <div className="self-stretch">
        {text}
      </div>
    </div>
  );
};

UpscalerTooltip.propTypes = {
  text: PropTypes.string.isRequired, // Ensure text is a string
  style: PropTypes.object, // Allow style to be optional
};

UpscalerTooltip.defaultProps = {
  style: {}, // Default to an empty object if no style is provided
};

export default UpscalerTooltip;
