import React, { useState } from 'react';
import PlanCard from './PlanCard';
import TokenPackCard from './TokenPurchaseCard';
import ToggleSwitch from '../../components/shared/ToggleSwitch'; // Use your custom ToggleSwitch component
import '../../styles/globals.css';

// Import the shared hover background images
import sharedHover from '../Assets/single_image.png';
import sharedTallHover from '../Assets/single_image_tall.png';

export default function SubscriptionAndTokens() {
  const [billingPeriod, setBillingPeriod] = useState('Quarterly');
  const [showAllResolutions, setShowAllResolutions] = useState(false);

  const applyDiscount = (price) =>
    billingPeriod === 'Yearly' ? Math.floor(price * 0.8) : price;

  const handleToggle = () => {
    setBillingPeriod((prev) => (prev === 'Quarterly' ? 'Yearly' : 'Quarterly'));
  };

  const plans = [
    {
      title: 'Standard Subscription',
      tokens: 300,
      priceMonthly: 29,
      priceQuarterly: 87,
      priceYearly: 276, // Updated yearly price
      features: [
        'Best for small teams and small recurring projects',
        'Email support',
        'Tokens roll over monthly',
      ],
      hoverBackgroundImage: sharedHover,
      hoverBackgroundPosition: '0% 0%', // Position for Standard plan
      priceId: 'price_1Q2fMQRutoVG5e03W4NhtjG2', // Added priceId
      discountedPriceId: 'price_1Q2fTXRutoVG5e03U09EX6M6', // Added discountedPriceId
    },
    {
      title: 'Pro Subscription',
      tokens: 750,
      priceMonthly: 59,
      priceQuarterly: 177,
      priceYearly: 564, // Updated yearly price
      features: [
        'Best for small teams working on a few projects',
        'Dedicated support',
        'Tokens roll over monthly',
      ],
      hoverBackgroundImage: sharedHover,
      hoverBackgroundPosition: '50% 0%', // Position for Pro plan
      priceId: 'price_1Q2fP3RutoVG5e0388SKJU2s', // Added priceId
      discountedPriceId: 'price_1Q2fVURutoVG5e030gIDP6ea', // Added discountedPriceId
    },
    {
      title: 'Studio Subscription',
      tokens: 2000,
      priceMonthly: 139,
      priceQuarterly: 417,
      priceYearly: 1332, // Updated yearly price
      features: [
        'Best for mid-size teams working on multiple projects',
        'Dedicated support',
        'Tokens roll over monthly',
      ],
      hoverBackgroundImage: sharedHover,
      hoverBackgroundPosition: '100% 0%', // Position for Studio plan
      priceId: 'price_1Q2fRNRutoVG5e03uf2fDeaB', // Added priceId
      discountedPriceId: 'price_1Q2fWlRutoVG5e03PLxQybIf', // Added discountedPriceId
    },
  ];

  const tokenPacks = [
    {
      packName: 'Starter Pack',
      tokens: 50,
      price: 10,
      description: 'Best for quick tasks',
      hoverBackgroundImage: sharedTallHover,
      hoverBackgroundPosition: '0% 0%', // Position for Starter Pack
      priceId: 'price_1Q2fXlRutoVG5e03Aize8l9Q', // Added priceId
    },
    {
      packName: 'Creator Pack',
      tokens: 150,
      price: 25,
      description: 'Ideal for small projects',
      hoverBackgroundImage: sharedTallHover,
      hoverBackgroundPosition: '0% 25%', // Position for Creator Pack
      priceId: 'price_1Q2fbTRutoVG5e03XH1OkPqx', // Added priceId
    },
    {
      packName: 'Project Pack',
      tokens: 500,
      price: 75,
      description: 'Great for medium tasks',
      hoverBackgroundImage: sharedTallHover,
      hoverBackgroundPosition: '0% 50%', // Position for Project Pack
      priceId: 'price_1Q2faPRutoVG5e03TFUWlPdd', // Added priceId
    },
    {
      packName: 'Production Pack',
      tokens: 1200,
      price: 150,
      description: 'Perfect for large projects',
      hoverBackgroundImage: sharedTallHover,
      hoverBackgroundPosition: '0% 100%', // Position for Production Pack
      priceId: 'price_1Q2fbTRutoVG5e03XH1OkPqx', // Added priceId
    },
  ];

  const resolutions = [
    { resolution: '640x480', commonName: 'VGA', aspectRatio: '4:3', tokens2x: 2, tokens3x: 3, tokens4x: 5 },
    { resolution: '800x600', commonName: 'SVGA', aspectRatio: '4:3', tokens2x: 2, tokens3x: 5, tokens4x: 8 },
    { resolution: '1024x768', commonName: 'XGA', aspectRatio: '4:3', tokens2x: 4, tokens3x: 8, tokens4x: 13 },
    { resolution: '1280x720', commonName: '720p / HD', aspectRatio: '16:9', tokens2x: 4, tokens3x: 9, tokens4x: 15 },
    { resolution: '1280x800', commonName: 'WXGA', aspectRatio: '16:10', tokens2x: 5, tokens3x: 10, tokens4x: 17 },
    { resolution: '1600x1200', commonName: 'UXGA', aspectRatio: '4:3', tokens2x: 8, tokens3x: 18, tokens4x: 31 },
    { resolution: '1920x1080', commonName: '1080p / FullHD', aspectRatio: '16:9', tokens2x: 9, tokens3x: 19, tokens4x: 34 },
    { resolution: '1920x1200', commonName: 'WUXGA', aspectRatio: '16:10', tokens2x: 10, tokens3x: 21, tokens4x: 37 },
    { resolution: '2160x2160', commonName: '1:1 4K', aspectRatio: '1:1', tokens2x: 19, tokens3x: 42, tokens4x: 75 },
    { resolution: '2560x1080', commonName: 'UW-FHD', aspectRatio: '21:9', tokens2x: 11, tokens3x: 25, tokens4x: 45 },
    { resolution: '2560x1440', commonName: '1440p / QHD', aspectRatio: '16:9', tokens2x: 15, tokens3x: 34, tokens4x: 59 },
    { resolution: '3440x1440', commonName: 'UW-QHD', aspectRatio: '21:9', tokens2x: 20, tokens3x: 45, tokens4x: 79 },
    { resolution: '3840x2160', commonName: '4K / UHD', aspectRatio: '16:9', tokens2x: 34, tokens3x: 75, tokens4x: 133 },
    { resolution: '5120x2160', commonName: '5K2K', aspectRatio: '21:9', tokens2x: 45, tokens3x: 100, tokens4x: 177 },
    { resolution: '7680x4320', commonName: '8K', aspectRatio: '16:9', tokens2x: 133, tokens3x: 299, tokens4x: 531 },
  ];

  return (
    <div className="flex flex-col md:flex-row justify-start items-start gap-6 w-full">
      {/* Subscription Plans Section */}
      <div className="flex-grow pt-4 border-t border-white/20 flex flex-col justify-start items-start gap-5">
        <div className="w-full flex justify-between items-center">
          <div className="flex flex-col justify-start items-start">
            <div className="text-white text-15 font-normal leading-tight">Subscription Plans</div>
            <div className="text-white/50 text-13 font-normal leading-none">
              Monthly recurring tokens refills for creative professionals and teams
            </div>
          </div>
          <div className="flex items-center shrink-0 gap-2">
            <div className="text-white/50 text-15 leading-tight">Yearly discount (20%)</div>
            <ToggleSwitch
              enabled={billingPeriod === 'Yearly'}
              onToggle={handleToggle}
              variant="subscription"
            />
          </div>
        </div>

        {/* Plan Cards */}
        <div className="grid grid-cols-3 items-start gap-4 flex-wrap">
          {plans.map((plan, index) => {
            const selectedPriceId =
              billingPeriod === 'Yearly' ? plan.discountedPriceId : plan.priceId;

            return (
              <PlanCard
                key={index}
                title={plan.title}
                tokens={plan.tokens}
                priceMonthly={applyDiscount(plan.priceMonthly)}
                priceQuarterly={applyDiscount(plan.priceQuarterly)}
                priceYearly={plan.priceYearly}
                features={plan.features}
                hoverBackgroundImage={plan.hoverBackgroundImage}
                hoverBackgroundPosition={plan.hoverBackgroundPosition}
                billingPeriod={billingPeriod}
                priceId={selectedPriceId}
              />
            );

          })}
        </div>

        {/* Resolution Table with Expand/Collapse Animation */}
        <div className="w-full pt-4 mt-8 border-t border-white/20 flex flex-col justify-start items-start gap-5">
          <div className="text-white text-15 font-normal leading-tight">Tokens Spend Reference Table</div>
          <div className="w-full flex-col justify-start items-start gap-2 flex relative">
            <div
              className="w-full overflow-hidden transition-all duration-500 ease-in-out"
              style={{
                height: showAllResolutions ? `${resolutions.length * 38 + 10}px` : `${6 * 38 + 10}px`,
              }}
            >
              <table className="w-full">
                <thead>
                  <tr className="border-b border-white/20">
                    <th className="text-left text-white/50 text-sm font-normal p-2">Resolution</th>
                    <th className="text-left text-white/50 text-sm font-normal p-2">Common name</th>
                    <th className="text-left text-white/50 text-sm font-normal p-2">Aspect ratio</th>
                    <th className="text-center text-white/50 text-sm font-normal p-2">Upscale factor</th>
                    <th className="text-center text-white/50 text-sm font-normal p-2">2x</th>
                    <th className="text-center text-white/50 text-sm font-normal p-2">3x</th>
                    <th className="text-center text-white/50 text-sm font-normal p-2">4x</th>
                  </tr>
                </thead>
                <tbody>
                  {resolutions.map((res, index) => (
                    <tr key={index} className="border-b border-white/20">
                      <td className="text-white/70 text-sm font-normal p-2">{res.resolution}</td>
                      <td className="text-white/70 text-sm font-normal p-2">{res.commonName}</td>
                      <td className="text-white/70 text-sm font-normal p-2">{res.aspectRatio}</td>
                      <td className="text-center text-white/70 text-sm font-normal p-2"></td>
                      <td className="text-center text-white/70 text-sm font-normal p-2">{res.tokens2x}</td>
                      <td className="text-center text-white/70 text-sm font-normal p-2">{res.tokens3x}</td>
                      <td className="text-center text-white/70 text-sm font-normal p-2">{res.tokens4x}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>

            {/* Smooth Gradient Transition */}
            {!showAllResolutions && (
              <div className="w-full h-[100px] bg-gradient-to-t from-[#151515] to-transparent absolute bottom-0.5 transition-all duration-500 ease-in-out pointer-events-none" />
            )}

            {/* Expand/Collapse Button */}
            <div className="w-full flex justify-center relative">
              <button
                onClick={() => setShowAllResolutions(!showAllResolutions)}
                className={`h-8 px-3 bg-[#151515] rounded border border-white/20 flex justify-center items-center transition-all duration-300 ${showAllResolutions ? 'mt-4' : ''
                  }`}
                style={{
                  position: showAllResolutions ? 'relative' : 'absolute',
                  bottom: showAllResolutions ? 'auto' : '35px',
                  width: 'fit-content',
                }}
              >
                <div className="text-[#d5d5d5] text-[15px] font-normal leading-tight">
                  {showAllResolutions ? 'Show Less' : 'Expand more resolutions'}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className="py-6 flex-col justify-center items-center gap-2.5 flex">
        <div className="text-white text-15 leading-tight">OR</div>
      </div>

      {/* Token Packs Section */}
      <div className="w-56 pt-4 border-t border-white/20 flex flex-col justify-start items-start gap-5">
        <div className="flex-col justify-start items-start flex">
          <div className="text-white text-15 leading-tight">Token Packs</div>
          <div className="text-white/50 text-13 leading-none">
            One-time tokens purchase
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-2 flex">
          {tokenPacks.map((pack, index) => (
            <TokenPackCard
              key={index}
              packName={pack.packName}
              tokens={pack.tokens}
              price={pack.price}
              description={pack.description}
              hoverBackgroundImage={pack.hoverBackgroundImage}
              hoverBackgroundPosition={pack.hoverBackgroundPosition}
              priceId={pack.priceId} // Passed the priceId for each token pack
            />
          ))}
        </div>
      </div>
    </div>
  );
}
