import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/ImageSlider.css';

const ImageSlider = ({
  originalUrl,
  comparisonUrl,
  uploadedImageWidth,
  uploadedImageHeight,
  scale,
  containerRef,
}) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);
  const GRAB_RANGE = 15; // 15px range for interaction

  const calculateSliderPosition = (clientX) => {
    if (sliderRef.current && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerX = (clientX - containerRect.left) / scale;
      const relativePosition = (containerX / (containerRect.width / scale)) * 100;
      return Math.min(Math.max(relativePosition, 0), 100);
    }
    return 50;
  };

  const isWithinLineRange = (clientX) => {
    if (sliderRef.current && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const sliderPixelPosition = (sliderPosition / 100) * (containerRect.width / scale);
      const clickPosition = (clientX - containerRect.left) / scale;
      return Math.abs(clickPosition - sliderPixelPosition) <= GRAB_RANGE;
    }
    return false;
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newPosition = calculateSliderPosition(e.clientX);
      setSliderPosition(newPosition);
    } else if (isWithinLineRange(e.clientX)) {
      sliderRef.current.style.cursor = 'grab';
    } else {
      sliderRef.current.style.cursor = 'auto';
    }
  };

  const handleMouseDown = (e) => {
    e.stopPropagation();
    if (isWithinLineRange(e.clientX)) {
      setIsDragging(true);
      const newPosition = calculateSliderPosition(e.clientX);
      setSliderPosition(newPosition);
    }
  };

  const handleTouchStart = (e) => {
    e.stopPropagation();
    if (isWithinLineRange(e.touches[0].clientX)) {
      setIsDragging(true);
      const newPosition = calculateSliderPosition(e.touches[0].clientX);
      setSliderPosition(newPosition);
    }
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      const newPosition = calculateSliderPosition(e.touches[0].clientX);
      setSliderPosition(newPosition);
    }
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', stopDragging);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', stopDragging);
    }
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', stopDragging);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', stopDragging);
    };
  }, [isDragging]);

  return (
    <div
      className="slider-container"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      ref={sliderRef}
      style={{
        width: `${uploadedImageWidth}px`,
        height: `${uploadedImageHeight}px`,
      }}
    >
      <img
        src={originalUrl}
        alt="Original"
        className="slider-image"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <img
        src={comparisonUrl}
        alt="Comparison"
        className="slider-image slider-image-upscaled"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          clipPath: `inset(0 0 0 ${sliderPosition}%)`,
        }}
      />
      <div 
        className="slider-line" 
        style={{ 
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: `${sliderPosition}%`,
          width: `${GRAB_RANGE * 2}px`,
          transform: `translateX(-${GRAB_RANGE}px)`,
          cursor: isDragging ? 'grabbing' : 'grab',
          
        }}
      >
        <div 
          className="slider-visual-line"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '50%',
            width: '1px',
            backgroundColor:  'rgba(255, 255, 255, 0.6)',
            transform: 'translateX(-50%)',
          }}
        />
        <div className="slider-handle"></div>
      </div>
    </div>
  );
};

ImageSlider.propTypes = {
  originalUrl: PropTypes.string.isRequired,
  comparisonUrl: PropTypes.string.isRequired,
  uploadedImageWidth: PropTypes.number.isRequired,
  uploadedImageHeight: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default ImageSlider;
