import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ProfileCircle from '../ProfileCircle';
import ColorToggleButton from '../ColorToggleButton';

const ZoomControls = ({
  zoomLevel,
  onZoomIn,
  onZoomOut,
  isDraggingEnabled,
  onToggleDragging,
  profileData,
}) => {
  const location = useLocation();

  // Determine if the current path should hide zoom and drag controls
  const isLandingOrUploadPage =
    location.pathname === '/home' ||
    location.pathname === '/upload' ||
    location.pathname === '/profile';

  return (
    <div className="fixed bottom-4 left-4 flex items-center gap-2 z-50">
      {/* Always show the ProfileCircle with updated profileData */}
      <div className="hover:opacity-80">
    <ProfileCircle profileData={profileData} />
  </div>
      {/* Conditionally render Zoom and Drag controls */}
      {!isLandingOrUploadPage && (
        <>
          {/* Zoom Controls */}
          <div
            className="ml-1 h-8 px-3 bg-sidebarBackground rounded-lg border border-borderColor flex justify-center items-center gap-1"
            style={{ minWidth: '100px' }} // Fixed min-width for uniform layout
          >
            {/* Zoom Out Button */}
            <button
              aria-label="Zoom Out"
              className={`w-4 h-4 flex justify-center items-center text-center text-sm font-semibold text-textLight ${
                zoomLevel <= 100 ? 'opacity-25 cursor-not-allowed' : 'opacity-50'
              } hover:opacity-100 transition-opacity duration-200`}
              onClick={onZoomOut}
              disabled={zoomLevel <= 100}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                viewBox="0 0 12 12"
                className="hover:stroke-[#ffffff] transition-colors duration-200"
              >
                <path
                  stroke="var(--white)" // Default color
                  strokeWidth="1.5"
                  d="M10.5 6h-9"
                />
              </svg>
            </button>

            {/* Zoom Level Display */}
            <div
              className="text-textLight text-xs font-mono leading-none opacity-50 text-center"
              style={{ width: '40px' }}
            >
              {zoomLevel}%
            </div>

            {/* Zoom In Button */}
            <button
              aria-label="Zoom In"
              className={`w-4 h-4 flex justify-center items-center text-center text-sm font-semibold text-textLight ${
                zoomLevel >= 300 ? 'opacity-25 cursor-not-allowed' : 'opacity-50'
              } hover:opacity-100 transition-opacity duration-200`}
              onClick={onZoomIn}
              disabled={zoomLevel >= 300}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                viewBox="0 0 12 12"
                className="hover:stroke-[#ffffff] transition-colors duration-200"
              >
                <path
                  stroke="var(--white)" // Default color
                  strokeWidth="1.5"
                  d="M6 1.5V6m0 4.5V6m0 0h4.5M6 6H1.5"
                />
              </svg>
            </button>
          </div>

          {/* Drag Toggle Button */}
          <button
            aria-label={isDraggingEnabled ? 'Disable Dragging' : 'Enable Dragging'}
            className={`mr-1 h-8 w-[106px] px-3 bg-sidebarBackground rounded-lg border border-borderColor flex justify-center items-center text-13 font-semibold ${
              isDraggingEnabled ? 'text-[var(--color-white)]' : 'text-[var(--color-w-50)]'
            }  transition-colors duration-200`}
            onClick={onToggleDragging}
          >
            {isDraggingEnabled ? 'Dragging On' : 'Dragging Off'}
          </button>

          {/* Color Toggle Button */}
          <ColorToggleButton />
        </>
      )}
    </div>
  );
};

ZoomControls.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  isDraggingEnabled: PropTypes.bool.isRequired,
  onToggleDragging: PropTypes.func.isRequired,
  profileData: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string,
    plan: PropTypes.string,
    tokens: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    position: PropTypes.string,
  }), // Define the shape of profileData
};

export default ZoomControls;
