import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UsageHistoryRow from './UsageHistoryRow';
import Cookies from 'js-cookie';
import { Spin, message } from 'antd';

const UsageHistorySection = () => {
  const [usageHistory, setUsageHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUsageHistory = async () => {
    const token = Cookies.get('token');
    if (!token) {
      message.error('You must be logged in to view usage history.');
      setLoading(false);
      return;
    }

    try {
      const [restoredResponse, upscaledResponse] = await Promise.all([
        fetch(`https://www.ek0go8g.vudoo.ai/api/imageRestoreGallery`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetch(`https://www.ek0go8g.vudoo.ai/api/imageUpscaleGallery`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      if (!restoredResponse.ok || !upscaledResponse.ok) {
        throw new Error('Failed to fetch usage history');
      }

      const [restoredData, upscaledData] = await Promise.all([
        restoredResponse.json(),
        upscaledResponse.json(),
      ]);

      // Normalize the data
      const normalizedRestoredImages = (restoredData.images || []).map((img) => ({
        ...img,
        tool: 'Image Restoration',
        dateProcessed: img.dateProcessed || img.createdAt,
        thumbnailUrl: img.thumbnailUrl || img.url,
      }));

      const normalizedUpscaledImages = (upscaledData.images || []).map((img) => ({
        ...img,
        tool: 'Image Upscaling',
        dateProcessed: img.dateProcessed || img.createdAt,
        thumbnailUrl: img.thumbnailUrl || img.url,
      }));

      // Combine images into one array
      const combinedData = [...normalizedRestoredImages, ...normalizedUpscaledImages];

      // Existing items in usageHistory
      const existingUsageMap = new Map(usageHistory.map(item => [item.jobId, item]));

      // Merge existing and new items without duplicates
      combinedData.forEach((item) => {
        existingUsageMap.set(item.jobId, item);
      });

      // Convert back to array
      const updatedUsageHistory = Array.from(existingUsageMap.values());

      // Sort the updated array
      updatedUsageHistory.sort((a, b) => new Date(b.dateProcessed) - new Date(a.dateProcessed));

      // Update state
      setUsageHistory(updatedUsageHistory);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching usage history:', error);
      setError('Failed to load usage history. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsageHistory(); // Fetch usage history when the component mounts
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#151515] text-white flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="pt-2 border-t border-white/15">
      {/* Display Error Message if Any */}
      {error && <div className="text-red-500 mb-4 text-center">{error}</div>}

      {/* Usage History Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full text-left">
          <thead>
            <tr className="text-14 text-white/50">
              <th className="pr-4 py-2 font-normal">IMAGE</th>
              <th className="px-4 py-2 font-normal">JOB ID</th>
              <th className="px-4 py-2 font-normal">DATE PROCESSED</th>
              <th className="px-4 py-2 font-normal">TOOL</th>
              <th className="px-4 py-2 font-normal">TOKENS SPENT</th>
            </tr>
          </thead>
          <tbody>
            {usageHistory.length > 0 ? (
              usageHistory.map((item, index) => (
                <UsageHistoryRow key={`usage-${index}`} item={item} />
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center text-gray-400">No usage history found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

UsageHistorySection.propTypes = {
  usageHistory: PropTypes.arrayOf(
    PropTypes.shape({
      jobId: PropTypes.string.isRequired,
      dateProcessed: PropTypes.string.isRequired,
      tool: PropTypes.string.isRequired,
      tokensSpent: PropTypes.number.isRequired,
      thumbnailUrl: PropTypes.string,
    })
  ),
};

export default UsageHistorySection;
