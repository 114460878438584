import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/ActionCard.css';
import '../../styles/globals.css';

const ActionCard = ({
  title,
  description,
  buttonText,
  onButtonClick,
  hoverBackgroundImage,
  comingSoon,
  betaBadge,
}) => (
  <div
    className={`action-card relative group overflow-hidden border border-[#333] bg-[#212121] rounded-lg flex flex-col justify-between cursor-pointer`}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '24px',
    }}
    onClick={comingSoon ? null : onButtonClick} // Prevent click if comingSoon
  >
    {/* Hover Background Image (Applies to both comingSoon and regular cards) */}
    {hoverBackgroundImage && (
      <div
        className={`absolute inset-0 bg-cover bg-center 
          opacity-0 group-hover:opacity-100 
          transition-all duration-[550ms] ease-in-out 
          transform 
          group-hover:rotate-[-4.25deg] 
          group-hover:scale-[1.15]
          origin-center
          will-change-transform, opacity`}
        style={{ backgroundImage: `url(${hoverBackgroundImage})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-0"></div>
      </div>
    )}

    {/* Content Wrapper */}
    
    <div className="action-card-content relative z-10">
    {betaBadge && (
        <div className="absolute -top-4 -right-4 badge opacity-70">Beta</div> // <-- This is the Beta badge
      )}
      <h2 className="h2 pb-3 text-white group-hover:text-white transition-none">{title}</h2>
      <p className="text-16 opacity-50 text-white group-hover:text-white transition-none">{description}</p>
    </div>

    {/* Footer */}
    <div className="action-card-footer relative z-10 mt-4">
      {comingSoon ? (
        <div className="text-18 py-3 text-[var(--color-w-30)] group-hover:text-[var(--color-w-30)] transition-none">
          Coming Soon
        </div>
      ) : (
        <div className="flex items-center transition-transform duration-500 ease-in-out transform translate-x-[-70%] group-hover:translate-x-0 hover:opacity-90">
          {/* Custom Button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onButtonClick();
            }}
            className="action-card-button flex items-center justify-center text-black font-medium bg-white border-none py-2 px-6 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-[0.1ms] ease-out"
            aria-label={`Perform action: ${buttonText}`}
          >
            {buttonText}
          </button>

          {/* Arrow Transition */}
          <div
            className="action-card-icon flex items-center justify-center bg-transparent p-2 rounded-full border-2 border-[#333] -ml-2 group-hover:bg-white group-hover:border-white group-hover:text-black transition-all duration-300 ease-in-out"
            style={{ color: '#333' }} // Initial color for the border and arrow
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M7 17L17 7m0 0v10m0-10H7" />
            </svg>
          </div>
        </div>
      )}
    </div>
  </div>
);

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string, // Make buttonText optional
  onButtonClick: PropTypes.func, // Make onButtonClick optional
  hoverBackgroundImage: PropTypes.string,
  comingSoon: PropTypes.bool, // New prop type
  betaBadge: PropTypes.bool,
};

ActionCard.defaultProps = {
  buttonText: '',
  onButtonClick: () => {},
  hoverBackgroundImage: '',
  comingSoon: false, // Default value
};

export default ActionCard;
