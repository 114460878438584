import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import background from '../components/Assets/background.png';

export default function BetaAccessPage() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  // Define the shared password here
  const betaPassword = 'vudoobeta'; // Replace with your shared password

  const handleBetaAccess = (e) => {
    e.preventDefault();
    if (password === betaPassword) {
      console.log("Password is correct, granting beta access");
      // Set a flag in session storage indicating that beta access is granted
      sessionStorage.setItem('betaAccessGranted', 'true');
      navigate('/login'); // Redirect to the LoginPage if the password is correct
    } else {
      console.log("Incorrect password");
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-[#0A0A0A] bg-cover bg-center"
      style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <div className="w-full max-w-[376px]">
        <div className="bg-[#151515] rounded-lg p-8 shadow-xl transition-all duration-300">
          <h2 className="text-2xl font-serif text-white mb-4">Vudoo Beta Access</h2>
          <p className="text-gray-400 mb-3">
          Your early input directly shapes our AI tools' development for creative workflows.
          </p>
          <p className="text-gray-400 mb-8">
          Enter your beta password to continue.
          </p>
          <form onSubmit={handleBetaAccess} className="space-y-4">
            <div className="relative">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-[44px] bg-transparent text-white border border-gray-600 rounded-lg py-2 px-4 focus:outline-none focus:border-white"
                placeholder="Beta password"
                aria-label="Beta password"
                required
              />
            </div>
            <button
              type="submit"
              className="h-12 pr-2.5 bg-[#151515] rounded-lg border border-white/20 justify-center items-center gap-4 inline-flex w-full"
            >
              <div className="text-white text-base font-medium font-['Test Söhne'] leading-snug">
                Access Beta
              </div>
            </button>
          </form>
          {error && <p className="mt-4 text-red-500">{error}</p>}
        </div>
      </div>
    </div>
  );
}
