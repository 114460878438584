import React, { useState, useEffect } from "react";
import { Upload, Spin, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import "../styles/UploadPage.css";
import GalleryItem from "../components/sharedCards/GalleryItem";
import ZoomControls from "../components/shared/ZoomControls";
import restorationHoverBackground from "../components/Assets/restoration-hover-background.png";
import upscalerHoverBackground from "../components/Assets/upscaler-hover-background.png";
import Lottie from "react-lottie";
import lottieAnimation from "../components/Assets/loading-animation.json";
import Hotjar from "@hotjar/browser";

// New imports for Loom video thumbnails
import restorationThumbnail from "../components/Assets/restoration-thumbnail.jpg";
import upscalerThumbnail from "../components/Assets/upscaler-thumbnail.jpg";

const { Dragger } = Upload;

const UploadPage = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [instructionText, setInstructionText] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [loadingGallery, setLoadingGallery] = useState(true);
  const [tokensAvailable, setTokensAvailable] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [hoverBackground, setHoverBackground] = useState("");
  const [loomVideoUrl, setLoomVideoUrl] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Lottie Animation Options
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const type = queryParams.get("type");

      if (type === "restoration") {
        setPageTitle("Image Restoration");
        setInstructionText("Upload your old or damaged photo. Our AI will work to repair imperfections and enhance overall quality. Results may vary as we refine this beta feature.");
        setHoverBackground(restorationHoverBackground);
        setLoomVideoUrl("https://www.loom.com/embed/RESTORATION_VIDEO_ID"); // Replace with actual video ID
        setVideoThumbnail(restorationThumbnail); // Use restoration thumbnail
      } else if (type === "upscaler") {
        setPageTitle("Image Upscale");
        setInstructionText(
          "Upload your AI-generated image to enhance its resolution and quality. Our AI will process it to deliver pro-grade results ready for commercial use."
        );
        setHoverBackground(upscalerHoverBackground);
        setLoomVideoUrl("https://www.loom.com/embed/UPSCALER_VIDEO_ID"); // Replace with actual video ID
        setVideoThumbnail(upscalerThumbnail); // Use upscaler thumbnail
      }

      await fetchUserProfile();
      await fetchGalleryImages(type);
      setLoadingGallery(false);
    };

    fetchData();
  }, [location]);

  const fetchUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) return;

    try {
      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const profileData = await response.json();
      setTokensAvailable(profileData.credits || 0);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      message.error("Failed to load user profile.");
    }
  };

  const fetchGalleryImages = async (type) => {
    const token = Cookies.get("token");
    if (!token) return;

    const endpoint =
      type === "restoration"
        ? "https://www.ek0go8g.vudoo.ai/api/imageRestoreGallery"
        : "https://www.ek0go8g.vudoo.ai/api/imageUpscaleGallery";

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch images");
      }

      const { images } = await response.json();

      const normalizedImages = (images || []).map((img) => ({
        ...img,
        uploadDate: img.dateProcessed || img.createdAt || "Unknown Date",
        toolUsed:
          type === "restoration" ? "Image Restoration" : "Image Upscaling",
      }));

      // Remove duplicates
      const imagesMap = new Map();

      normalizedImages.forEach((image) => {
        imagesMap.set(image.jobId, image);
      });

      const uniqueImages = Array.from(imagesMap.values());

      setGalleryImages(uniqueImages);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleFileUpload = async (info) => {
    const file = info.file.originFileObj || info.file;

    if (!file) return;

    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = Cookies.get("token");
      if (!token) {
        message.error("You must be logged in to upload images.");
        setIsUploading(false);
        return;
      }

      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/upload", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const data = await response.json();
      const cloudinaryUrl = data.cloudinaryUrl;

      sessionStorage.setItem("uploadedImageUrl", cloudinaryUrl);
      message.success("Image uploaded successfully!");

      const queryParams = new URLSearchParams(location.search);
      const type = queryParams.get("type");

      if (type === "restoration") {
        navigate("/restoration");
      } else if (type === "upscaler") {
        navigate("/upscaler");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Upload failed!");
    } finally {
      setIsUploading(false);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 10, 200)); // Limit zoom to 200%
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 10, 50)); // Limit zoom to 50%
  };

  return (
    <div className="w-full min-h-screen bg-[#151515] flex flex-col items-center">
      {/* Home Button */}
      <div
        className="h-10 w-10 fixed top-4 left-4 z-50 cursor-pointer flex items-center justify-center bg-[#212121] border border-white/20 rounded-lg hover:bg-[#3a3a3a] transition-all duration-300 ease-in-out"
        onClick={() => navigate("/home")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="var(--white)"
            strokeWidth="1.5"
            d="M2.415 8.711a2.25 2.25 0 0 1 1.024-1.886l5.333-3.467a2.25 2.25 0 0 1 2.453 0l5.333 3.467a2.25 2.25 0 0 1 1.024 1.886v6.79c0 .69-.56 1.25-1.25 1.25h-3.667a.25.25 0 0 1-.25-.25v-3a1.75 1.75 0 0 0-1.75-1.75H9.332a1.75 1.75 0 0 0-1.75 1.75v3a.25.25 0 0 1-.25.25H3.665c-.69 0-1.25-.56-1.25-1.25V8.71Z"
          />
        </svg>
      </div>

      {/* Main Content */}
      <div
        className="flex flex-col md:flex-row justify-center items-center gap-20 w-full mt-20 p-10"
        style={{ height: "calc(100vh - 260px)" }}
      >
        {/* Left section: title and instructions */}
        <div className="w-full md:w-[400px] text-white flex flex-col gap-5">
          <h1 className="h2 font-normal text-left m-0">{pageTitle}</h1>
          <p className="text-16 opacity-60 mb-6">{instructionText}</p>
          <div
            className="w-40 h-24 rounded-lg border border-white/30 flex justify-center items-center cursor-pointer bg-[#212121] relative"
            onClick={() => setIsVideoPlaying(true)}
          >
            {!isVideoPlaying ? (
              <>
                {/* Adding the thumbnail as a background image */}
                <img
                  src={videoThumbnail}
                  alt="Video Thumbnail"
                  className="w-full h-full rounded-lg absolute inset-0 object-cover"
                />
                <div className="w-10 h-10 bg-white/30 rounded-full backdrop-blur-sm drop-shadow-lg flex justify-center items-center z-10">
                  {/* Play Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="white"
                    viewBox="0 0 12 12"
                  >
                    <path fill="#fff" d="M2 12V0l10 6-10 6Z" />
                  </svg>
                </div>
              </>
            ) : (
              <iframe
                src={loomVideoUrl}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg"
              />
            )}
          </div>
        </div>

        {/* Right section: Upload box */}
        <Dragger
          name="file"
          accept="image/*"
          beforeUpload={() => false}
          onChange={handleFileUpload}
          className="border border-[var(--color-w-30)] border-dashed upload-dragger w-full max-h-[500px] md:w-[600px] flex flex-col justify-items-stretch rounded-xl items-center bg-[var(--coal-800)] cursor-pointer relative group"
          showUploadList={false}
        >
          {/* Hover Background Image */}
          <div
            className="rounded-xl hover-background absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            style={{ backgroundImage: `url(${hoverBackground})` }}
          >
            {/* Semi-transparent overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-0"></div>
          </div>

          {/* Upload Content */}
          <div className="h-96 w-full flex flex-col justify-center items-center relative z-10">
            {!isUploading ? (
              <>
                <div className="w-18 h-18 border border-white/30 rounded-full mb-6 flex justify-center items-center group-hover:bg-white/10 transition-colors duration-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    stroke="white"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 3v9m0 9v-9m0 0h9m-9 0H3" />
                  </svg>
                </div>
                <p className="h2 text-white mb-2">Click here to upload image</p>
                <p className="text-white text-18 opacity-50">
                  or drag and drop here
                </p>
                {pageTitle === "Image Restoration" && (
                  <div className="absolute w-full bottom-0 flex justify-center items-center">
                    <div className="badge">
                      Restorer is in beta, results may vary
                    </div>
                  </div>
                )}
              </>
            ) : (
              // Lottie Animation during upload
              <Lottie options={lottieOptions} height={78} width={78} />
            )}
          </div>
        </Dragger>
      </div>

      {/* Gallery Images */}
      <div
        className="flex gap-16 border-t border-white/20 mx-auto "
        style={{ width: "calc(100% - 190px)" }}
      >
        <div className="flex justify-between items-start mb-6">
          <div className="flex flex-col gap-6 w-44">
            <div className="flex space-x-4">
              <div className="text-white px-1 pt-4 border-t-2 border-white">
                My Library
              </div>
            </div>
          </div>
        </div>

        <div
          className="grow grid gap-6 pt-4"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
          }}
        >
          {loadingGallery ? (
            <div className="text-white text-center col-span-full">
              Loading images...
            </div>
          ) : galleryImages.length > 0 ? (
            galleryImages.map((image) => (
              <GalleryItem key={image.id} image={image} />
            ))
          ) : (
            <div className="text-white text-center col-span-full">
              No images found. Start by uploading your first image!
            </div>
          )}
        </div>
      </div>

      {/* ZoomControls Component */}
      <ZoomControls
        zoomLevel={zoomLevel}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
      />
    </div>
  );
};

export default UploadPage;
