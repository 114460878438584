import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ZoomControls from '../components/shared/ZoomControls';
import DownloadSection from '../components/shared/DownloadSection';
import ImageDisplay from '../components/shared/ImageDisplay';

const ImageModal = ({ isOpen, onClose }) => {
  const [image, setImage] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [resolution, setResolution] = useState('Calculating...');
  const [zoomLevel, setZoomLevel] = useState(100);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
      setTimeout(() => setIsVisible(true), 10); // Start transition after mounting
    } else {
      setIsVisible(false);
    }
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const storedImage = sessionStorage.getItem('selectedImage');
      if (storedImage) {
        const parsedImage = JSON.parse(storedImage);
        setImage(parsedImage);
        if (!parsedImage.resolution || parsedImage.resolution === 'Unknown Resolution') {
          const img = new Image();
          img.src = parsedImage.url;
          img.onload = () => setResolution(`${img.naturalWidth}x${img.naturalHeight}`);
          img.onerror = () => setResolution('Unavailable');
        } else {
          setResolution(parsedImage.resolution);
        }
      }
    } else {
      setImage(null);
      setResolution('Calculating...');
      sessionStorage.removeItem('selectedImage');
    }
  }, [isOpen]);

  const handleDownload = async (format) => {
    if (!image) return;
    setIsDownloading(true);
    try {
      const response = await fetch(image.url, { method: 'GET' });
      if (!response.ok) throw new Error('Failed to download image');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;

      // Use the selected format for download
      link.setAttribute('download', `image_${image.id}.${format}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
      alert('Failed to download the image.');
    }
    setIsDownloading(false);
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 300); // Wait for transition to finish before unmounting
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 backdrop-blur-sm transition-all duration-300 ease-in-out ${
        isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
    >
      {/* Close Button Outside the Modal */}
      <button
        className="absolute top-8 right-8 text-white text-4xl font-bold focus:outline-none hover:text-gray-300 transition-all duration-300 ease-in-out"
        onClick={handleClose}
        aria-label="Close Modal"
        style={{ zIndex: 60 }}
      >
        &times;
      </button>

      {/* Main Modal Container */}
      <div
        className={`bg-coal-700 rounded-xl p-4 max-w-[90vw] max-h-[90vh] w-full h-full relative justify-center flex items-center transition-all duration-300 ease-in-out transform ${
          isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Left Section: Image Display */}
        <div className="flex-grow flex justify-center items-center p-2 rounded-l-lg transition-all duration-300 ease-in-out">
          {image ? (
            <ImageDisplay
              uploadedImageUrl={image.url}
              selectedImageUrl={image.url}
              isProcessing={false}
              isProcessed={true}
              zoomLevel={zoomLevel}
              isDraggingEnabled={true}
              upscaledImageUrl={image.url}
              showBadges={false}
            />
          ) : (
            <p className="text-white">Loading image...</p>
          )}
        </div>

        {/* Right Section: Metadata and Controls */}
        <div className="w-[30%] bg-[#222222] p-4 rounded-r-lg flex flex-col justify-between transition-all duration-300 ease-in-out">
          {/* Image Metadata */}
          <div className="flex flex-col gap-2 text-white text-sm mb-5">
            <ul className="space-y-1">
              <li className="flex justify-between">
                <span className="font-semibold">Processed On:</span>
                <span className="text-right">{image ? new Date(image.uploadDate).toLocaleDateString() : 'N/A'}</span>
              </li>
              <li className="flex justify-between">
                <span className="font-semibold">Resolution:</span>
                <span className="text-right">{resolution}</span>
              </li>
              <li className="flex justify-between">
                <span className="font-semibold">Tool Used:</span>
                <span className="text-right">{image ? image.toolUsed : 'N/A'}</span>
              </li>
            </ul>
          </div>

          {/* Download Section with minimalView */}
          <div className="mt-2">
            <DownloadSection handleDownload={handleDownload} outputResolution={resolution} isCollapsed={false} toggleCollapse={() => {}} minimalView={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

ImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImageModal;