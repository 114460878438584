import React, { useState } from 'react';
import Header from './Header';
import ZoomControls from './ZoomControls';

const SharedLayout = ({
  headerProps,
  sidebarContent,
  zoomControlsProps,
  children,
}) => {
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);

  const toggleDragging = () => {
    setIsDraggingEnabled((prev) => !prev);
  };

  // Extend zoomControlsProps with dragging state and toggle function
  const extendedZoomControlsProps = {
    ...zoomControlsProps,
    isDraggingEnabled,
    onToggleDragging: toggleDragging,
  };

  // Clone the children (ImageDisplay) and pass isDraggingEnabled prop
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isDraggingEnabled });
    }
    return child;
  });

  return (
    <div className="min-h-screen bg-darkBackground text-textLight relative overflow-hidden">
      {/* Header with consistent z-index */}
      <div className="relative z-50">
        <Header {...headerProps} />
      </div>

      {/* Main Content Container - Allow Scrolling */}
      <div className="flex h-[calc(100vh-0px)] relative overflow-y-scroll">
        {/* Centered Image Display with consistent background and padding */}
        <div className="flex-grow flex justify-center items-center relative z-0 mr-[350px] pl-8">
          {childrenWithProps}
        </div>

        {/* Floating Sidebar - Centered Vertically, Scrollable Content */}
        <div
          className="absolute top-1/2 right-3 left-auto transform -translate-y-1/2 overflow-y-auto max-h-[calc(100vh-32px)] flex-shrink-0 z-40 pointer-events-auto"
        >
          <div className="flex justify-center flex-col gap-3 backdrop-blur-md shadow-lg my-4 w-80">
            {sidebarContent}
          </div>
        </div>

        {/* Zoom Controls */}
        <div className="fixed bottom-4 left-4 z-40">
          <ZoomControls {...extendedZoomControlsProps} />
        </div>
      </div>
    </div>
  );
};

export default SharedLayout;
