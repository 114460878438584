import React, { useState, useEffect, useCallback } from 'react';
import { ReactComponent as InfoIcon } from '../Assets/info.svg';
import TooltipCard from './TooltipCard';
import { getPlanTooltipData } from './TooltipData';
import { getStripe } from '../../utils/stripe';
import { getAuth } from 'firebase/auth';
import '../../styles/globals.css';

export default function PlanCard({
  title,
  tokens,
  priceMonthly,
  priceQuarterly,
  priceYearly,
  features,
  hoverBackgroundImage,
  hoverBackgroundPosition,
  billingPeriod,
  priceId,
  discountedPriceId,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const tooltipData = getPlanTooltipData(title);

  // Use discountedPriceId if available; otherwise, fall back to priceId
  const selectedPriceId = discountedPriceId || priceId;

  // Check for active plan in localStorage
  useEffect(() => {
    const activePlan = localStorage.getItem('selectedPlan');
    if (activePlan === title) {
      setIsActive(true);
    }
  }, [title]);

  const handleCheckout = useCallback(async () => {
    if (isLoading || isActive) return; // Prevent checkout if plan is already active or loading
    setIsLoading(true);
    try {
      console.log(
        `Initiating checkout for priceId: ${selectedPriceId} in plan: ${title}`
      );

      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        alert("You need to be logged in to proceed with checkout.");
        return;
      }

      const idToken = await user.getIdToken();

      const response = await fetch(
        "https://www.ek0go8g.vudoo.ai/api/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            priceId: selectedPriceId,
            mode: "subscription",
            success_url: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}&success=true`,
            cancel_url: `${window.location.origin}/profile`,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to create checkout session: ${response.statusText}`
        );
      }

      const { sessionId } = await response.json();
      const stripe = await getStripe();

      // Update the selected plan in localStorage
      localStorage.setItem('selectedPlan', title);
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error during checkout:", error);
      alert("Checkout failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, selectedPriceId, title, isActive]);

  // Handle keyboard events for accessibility
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        handleCheckout();
      }
    },
    [handleCheckout]
  );

  return (
    <div
      className={`h-[400px] p-4 rounded-lg flex flex-col justify-between items-start shadow-md relative overflow-visible group cursor-pointer ${isLoading || isActive ? "opacity-50 pointer-events-none" : ""
        } ${isActive ? "border-2 border-green-500 bg-[var(--coal-700)]" : "bg-[var(--coal-800)]"}`}
      onClick={handleCheckout}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      aria-label={`Purchase ${title} plan for $${billingPeriod}`}
    >
      {/* Info Icon at the top-right corner */}
      <div
        className="absolute top-2 right-2 z-30"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={(e) => e.stopPropagation()} // Prevent card click
        onKeyDown={(e) => e.stopPropagation()} // Prevent keyboard events
        role="button"
        tabIndex={0}
        aria-label="More information"
      >
        <InfoIcon className="w-4 h-4 text-white opacity-80 cursor-pointer hover:opacity-100 transition-opacity duration-200" />
      </div>

      {/* Smooth Tooltip Card Display */}
      <div
        className={`absolute top-12 right-2 z-50 w-[260px] transition-all duration-300 ${showTooltip && tooltipData
          ? "opacity-100 transform translate-y-0"
          : "opacity-0 transform -translate-y-4 pointer-events-none"
          }`}
      >
        {tooltipData ? (
          <TooltipCard title="Estimated token spend" items={tooltipData} />
        ) : (
          <div className="text-white text-xs">No data available</div> // Fallback for empty data
        )}
      </div>

      {/* Hover Background Image */}
      {hoverBackgroundImage && (
        <div
          className={`rounded-lg absolute inset-0 bg-cover bg-center transition-opacity duration-300 ${isActive
            ? "opacity-100"
            : "opacity-0 group-hover:opacity-100"
            }`}
          style={{
            backgroundImage: `url(${hoverBackgroundImage})`,
            backgroundPosition: hoverBackgroundPosition,
          }}
        >
          {/* Semi-transparent overlay to ensure text readability */}
          <div className="absolute inset-0 bg-black bg-opacity-0"></div>
        </div>
      )}

      {/* Title and Tokens */}
      <div className="flex-grow relative z-10">
        <div className="text-white text-16">{title}</div>
        <div className="flex items-baseline gap-1 mt-1">
          <span className="text-4xl" style={{ fontFamily: "'Inter', sans-serif" }}>
            {tokens}
          </span>
          <span className="ml-0.5 text-white text-14 leading-tight">
            tokens/{billingPeriod === "Yearly" ? "mo" : "mo"}
          </span>
        </div>

        {/* Adjusted Price Display */}
        <div className="text-14 text-white/70 leading-tight mt-4">
          ${priceMonthly} /mo
          <br />
          <span className="text-white/50">
            {billingPeriod === "Yearly"
              ? `$${priceYearly} billed yearly`
              : billingPeriod === "Quarterly"
                ? `$${priceQuarterly} billed quarterly`
                : "Billed monthly"}
          </span>
        </div>
      </div>

      {/* Features List */}
      <div className="flex flex-col gap-2 mt-4 mb-2 relative z-10">
        {features.map((feature, index) => (
          <div key={index} className="flex gap-3">
            <div className="w-1.5 h-1.5 shrink-0 bg-white/50 rounded-full mt-1.5" />
            <div className="text-white/70 text-13 leading-none">{feature}</div>
          </div>
        ))}
      </div>

      {/* Adding a margin to create space between the features and the button */}
      <div className="mt-auto w-full relative z-10">
        <div className="my-4" />
        {/* Upgrade Button with group-hover styles */}
        <div className="w-full">
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from bubbling up to the card
              handleCheckout();
            }}
            disabled={isLoading || isActive}
            className={`w-full h-10 rounded-md border border-white/20 text-white text-16-m 
      group-hover:bg-[#d5d5d5] group-hover:text-black hover:opacity-90
      transition-all duration-300 ${isLoading || isActive ? "opacity-50 cursor-not-allowed" : ""
              }`}
          >
            {isLoading ? "Processing..." : isActive ? "Active" : `Upgrade to ${title.split(" ").slice(0, 1).join(" ")}`}
          </button>
        </div>
      </div>
    </div>
  );
}
