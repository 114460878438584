// TooltipData.js

export const getPlanTooltipData = (planName) => {
  const data = {
    Standard: [
      { input: '480p (854×480)', images: 150, output: '1080p (2x upscale)' },
      { input: '720p (1280×720)', images: 75, output: '1440p (2x upscale)' },
      { input: 'FullHD (1920×1080)', images: 33, output: '4K (2x upscale)' },
    ],
    Pro: [
      { input: '480p (854×480)', images: 375, output: '1080p (2x upscale)' },
      { input: '720p (1280×720)', images: 187, output: '1440p (2x upscale)' },
      { input: 'FullHD (1920×1080)', images: 83, output: '4K (2x upscale)' },
    ],
    Studio: [
      { input: '480p (854×480)', images: 1000, output: '1080p (2x upscale)' },
      { input: '720p (1280×720)', images: 500, output: '1440p (2x upscale)' },
      { input: 'FullHD (1920×1080)', images: 222, output: '4K (2x upscale)' },
    ],
  };
  // Normalize planName to match the keys
  const normalizedPlanName = planName.replace(' Subscription', '');
  return data[normalizedPlanName] || [];
};

  
  export const getTokenTooltipData = (tokenPack) => {
    const data = {
      'Starter Pack': [
        { input: '480p (854×480)', images: 25, output: '1080p (2x upscale)' },
        { input: '720p (1280×720)', images: 12, output: '1440p (2x upscale)' },
        { input: 'FullHD (1920×1080)', images: 5, output: '4K (2x upscale)' },
      ],
      'Creator Pack': [
        { input: '480p (854×480)', images: 75, output: '1080p (2x upscale)' },
        { input: '720p (1280×720)', images: 37, output: '1440p (2x upscale)' },
        { input: 'FullHD (1920×1080)', images: 16, output: '4K (2x upscale)' },
      ],
      'Project Pack': [
        { input: '480p (854×480)', images: 250, output: '1080p (2x upscale)' },
        { input: '720p (1280×720)', images: 125, output: '1440p (2x upscale)' },
        { input: 'FullHD (1920×1080)', images: 55, output: '4K (2x upscale)' },
      ],
      'Production Pack': [
        { input: '480p (854×480)', images: 600, output: '1080p (2x upscale)' },
        { input: '720p (1280×720)', images: 300, output: '1440p (2x upscale)' },
        { input: 'FullHD (1920×1080)', images: 133, output: '4K (2x upscale)' },
      ],
    };
    return data[tokenPack] || [];
  };
  
