import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../styles/globals.css';

const Gallery = ({
  images,
  imageMetadata,
  onThumbnailClick,
  isCollapsed,
  toggleCollapse,
}) => {
  const [selectedImage, setSelectedImage] = useState(images?.[0] || '');
  const [selectedMetadata, setSelectedMetadata] = useState(
    imageMetadata?.[0] || { width: 0, height: 0, format: 'Unknown', scaleFactor: 'N/A' }
  );

  const handleThumbnailClick = (imageUrl, metadata) => {
    setSelectedImage(imageUrl);
    setSelectedMetadata(metadata);
    
    if (onThumbnailClick) {
      onThumbnailClick(imageUrl, metadata);
    }
  };

  const getLabelText = () => {
    const index = images.indexOf(selectedImage);
    return index === 0 ? 'ORIGINAL' : `VARIANT ${index}`;
  };

  const selectedIndex = images.indexOf(selectedImage);

  useEffect(() => {
    if (images.length > 0 && imageMetadata.length === images.length) {
      const lastIndex = images.length - 1;
      const newImage = images[lastIndex];
      const newMetadata = imageMetadata[lastIndex] || { width: 0, height: 0, format: 'Unknown', scaleFactor: 'N/A' };
      setSelectedImage(newImage);
      setSelectedMetadata(newMetadata);
    }
  }, [images, imageMetadata]);

  if (!imageMetadata || imageMetadata.length === 0) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="flex flex-row justify-start space-x-6">
      <div className="bg-sidebarBackground w-[541px] rounded-lg border border-border overflow-hidden border-white/10">
        <div
          className="p-4 flex justify-between items-center cursor-pointer"
          onClick={toggleCollapse}
        >
          <div className="text-16">
            Input and Variants
          </div>
          <div className="px-1 flex items-center justify-center gap-2.5">
            <div
              className={`w-2 h-2 rounded-full transform transition-transform duration-300 ${
                isCollapsed ? 'bg-white opacity-30' : 'bg-white'
              }`}
            />
          </div>
        </div>

        {/* Animated Collapsible Section */}
        <div
          className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
            isCollapsed ? 'max-h-0' : 'max-h-screen'
          }`}
        >
          <div className="p-4 flex justify-start items-center gap-4 -mt-2">
            <div className="relative w-1/3 h-auto relative">
              <div className="flex justify-center items-center bg-gray-800 rounded object-contain border border-white/20">
                <img
                  className="w-full h-full rounded object-contain"
                  src={selectedImage}
                  alt="Selected"
                />
              </div>
              <div className="w-full absolute -top-2.5 flex items-center justify-center">
                <div className="badge">
                  <div className="opacity-70 text-white text-[10px] font-semibold uppercase leading-[14px] tracking-wide">
                    {getLabelText()}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col grow justify-between items-start space-y-1">
              <div className="flex justify-between items-center w-full text-13">
                <span className="opacity-50">Resolution:</span>
                <span className="ml-1">{selectedMetadata.width} x {selectedMetadata.height}</span>
              </div>
              <div className="flex justify-between items-center w-full text-13">
                <span className="opacity-50">Format:</span>
                <span className="ml-1">{selectedMetadata.format}</span>
              </div>
              {selectedIndex !== 0 && selectedMetadata.scaleFactor !== 'N/A' && (
                <div className="flex justify-between items-center w-full text-13">
                  <span className="opacity-50">Scale Factor:</span>
                  <span className="ml-1">{selectedMetadata.scaleFactor}</span>
                </div>
              )}
            </div>
          </div>

          <div className="self-stretch h-px opacity-10 bg-white mx-4 my-1"/>

          <div className="p-4 max-h-[calc(30vh * 4)] grid grid-cols-3 gap-2 overflow-x-auto overflow-y-hidden">
            {images.map((imageUrl, index) => (
              <div
                key={index}
                className={`rounded object-cover border max-h-[30vh] ${
                  selectedImage === imageUrl ? 'border-2 border-[#d5d5d5]' : 'border-white/20'
                } cursor-pointer flex-shrink-0`}
                onClick={() => handleThumbnailClick(imageUrl, imageMetadata[index])}
              >
                <img
                  className="w-full h-full object-contain rounded"
                  src={imageUrl}
                  alt={`Variant ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      format: PropTypes.string,
      scaleFactor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  onThumbnailClick: PropTypes.func,
  isCollapsed: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
};

export default Gallery;
