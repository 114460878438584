// src/pages/LandingPage.jsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import GalleryItem from '../components/sharedCards/GalleryItem';
import ActionCard from '../components/sharedCards/ActionCard';
import Lottie from 'react-lottie';
import animationData from '../components/Assets/vudoo-logo-animation.json';
import ZoomControls from '../components/shared/ZoomControls'; // Import ZoomControls
import ImageModal from '../components/ImageModal'; // Import ImageModal
import '../styles/LandingPage.css';
import Hotjar from '@hotjar/browser';

// Import hover background images
import restoreHoverImage from '../components/Assets/restore-card.png';
import upscaleHoverImage from '../components/Assets/upscale-card.png';
import videoUpscaleHoverImage from '../components/Assets/video-card.png';
// Optional: Import a different image for "Coming Soon" if desired
// import comingSoonHoverImage from '../components/Assets/comingSoonImage.png';

import article1Thumbnail from './Assets/learn-section-placeholder-01.jpg';
import video1Thumbnail from './Assets/learn-section-placeholder-02.jpg';
import article2Thumbnail from './Assets/learn-section-placeholder-03.jpg';

const LandingPage = () => {
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    position: '',
    avatar: '/placeholder.svg?height=64&width=64',
  });

  const [userCredits, setUserCredits] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [images, setImages] = useState([]); // Initialize as empty array
  const [learnItems, setLearnItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('library');

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // Track selected image index

  // Lottie options for the animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

    // Hotjar Integration
    useEffect(() => {
      Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
    }, []);
    

  const fetchUserProfile = async () => {
    const token = Cookies.get('token');
    if (!token) {
      setIsLoggedIn(false);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/profile', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch user profile');

      const data = await response.json();
      setProfileData({
        name: data.name || 'User',
        email: data.email || '',
        position: data.position || '',
        avatar: data.photoURL || '/placeholder.svg?height=64&width=64',
      });

      setUserCredits(data.credits || 0);
      setIsLoggedIn(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setIsLoggedIn(false);
      setLoading(false);
    }
  };

  const fetchImages = async () => {
    const token = Cookies.get('token');
    if (!token) return;

    try {
      const [restoredResponse, upscaledResponse] = await Promise.all([
        fetch('https://www.ek0go8g.vudoo.ai/api/imageRestoreGallery', {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetch('https://www.ek0go8g.vudoo.ai/api/imageUpscaleGallery', {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      if (!restoredResponse.ok || !upscaledResponse.ok) {
        throw new Error('Failed to fetch images');
      }

      const [restoredData, upscaledData] = await Promise.all([
        restoredResponse.json(),
        upscaledResponse.json(),
      ]);

      // Normalize Restored Images
      const normalizedRestoredImages = (restoredData.images || []).map((img) => ({
        ...img,
        uploadDate: img.uploadedAt || img.createdAt,
        toolUsed: 'Image Restoration', // Define tool used based on endpoint
      }));

      // Normalize Upscaled Images
      const normalizedUpscaledImages = (upscaledData.images || []).map((img) => ({
        ...img,
        uploadDate: img.uploadedAt || img.createdAt,
        toolUsed: 'Image Upscaling', // Define tool used based on endpoint
      }));

      const combinedImages = [
        ...normalizedRestoredImages,
        ...normalizedUpscaledImages,
      ].sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)); // Sort by latest uploadDate

      setImages(combinedImages);
    } catch (error) {

    }
  };



  const fetchLearnItems = async () => {
    try {
      // Create the data array using the imported images
      const learnData = [
        { id: 1, title: 'Learn about Image Restoration', type: 'Article', thumbnailUrl: article1Thumbnail },
        { id: 2, title: 'Upscaling Basics', type: 'Video', thumbnailUrl: video1Thumbnail },
        { id: 3, title: 'Advanced Techniques', type: 'Article', thumbnailUrl: article2Thumbnail },
      ];

      // Set state with the imported data
      setLearnItems(learnData);
    } catch (error) {
      console.error('Failed to fetch learn items:', error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      await fetchUserProfile();
      await fetchImages();
      await fetchLearnItems();
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#151515] text-white flex justify-center items-center">
        <div>Loading...</div>
      </div>
    );
  }

  const handleRedirectToUpscaler = () => navigate('/upload?type=upscaler');
  const handleRedirectToRestoration = () => navigate('/upload?type=restoration');
  const handleRedirectToOutpaint = () => navigate('/upload?type=outpaint');
  const handleTokenInfoClick = () => {
    navigate(isLoggedIn ? '/profile' : '/login', { state: isLoggedIn ? { section: 'plans' } : {} });
  };

  const firstName = profileData.name.split(' ')[0];

  // Function to handle image click
  const handleImageClick = (index) => {
    setSelectedIndex(index);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedIndex(0);
  };

  return (
    <div className="min-h-screen bg-[var(--coal-900)] text-white p-4 pt-20">
      <header className="fixed top-0 left-0 right-0 flex justify-between items-center h-16 px-4 z-50 bg-transparent">
        {/* Overlay Effect that starts from the top of the screen */}
        <div
          className="absolute top-0 left-0 right-0 h-10 z-40"
          style={{
            background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent)",
            backdropFilter: "blur(1px)",
            WebkitBackdropFilter: "blur(1px)",
          }}
        ></div>

        {/* Logo Animation (unaffected by the blur) */}
        <button
          className="flex items-center focus:outline-none relative z-50" // Place on top of the blur effect
          onClick={() => navigate('/home')}
          style={{ backgroundColor: 'transparent', border: 'none' }}
          aria-label="Go to Home"
        >
          <Lottie options={defaultOptions} height={16} width={"auto"} />
        </button>

        {/* Tokens Left Button (unaffected by the blur) */}
        <div
          className="px-3 py-2 rounded-lg border border-[var(--color-border)] bg-[var(--coal-800)] text-sm cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300 relative z-50" // Place on top of the blur effect
          onClick={() => navigate(isLoggedIn ? "/profile" : "/login")}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              navigate(isLoggedIn ? "/profile" : "/login");
            }
          }}
          aria-label={isLoggedIn ? "Go to Profile" : "Go to Login"}
        >
          {userCredits !== null ? `${userCredits} tokens left` : "Fetching..."}
        </div>
      </header>



      {/* Main Content */}
      <div className="flex grow items-center" style={{ height: 'calc(100vh - 260px)' }}> {/* Adjust height as needed */}
        <div className="flex flex-col gap-3 justify-center content-center mx-auto mb-12 h-full max-h-[560px] max-w-[1000px]">


          <div className="flex flex-grow gap-3 px-1 text-16">
            <p>Hello, {firstName}!</p>
            <p className="opacity-40">Let’s do some magic together...</p>
          </div>

          {/* ActionCards Grid */}
          <div className="grid items-center grid-cols-1 md:grid-cols-3 gap-4 mb-8 h-full">
            <ActionCard
              title="Image Restore"
              description="Enhance, repair and restore old or damaged photos back to life."
              buttonText="New Restore"
              onButtonClick={handleRedirectToRestoration}
              hoverBackgroundImage={restoreHoverImage}
              betaBadge={true}

            />
            <ActionCard
              title="Image Upscale"
              description="Increase resolution and enhance details for lossless, pro-grade results."
              buttonText="New Upscale"
              onButtonClick={handleRedirectToUpscaler}
              hoverBackgroundImage={upscaleHoverImage}
            />

            {/* Third ActionCard - Coming Soon */}
            <ActionCard
              title="Video Upscale"
              description="Improve video resolution and quality. AI processing creates pro-grade footage for your projects."
              comingSoon={true} // Add this prop
              hoverBackgroundImage={videoUpscaleHoverImage} // Optional: Use a different image if desired
            />
          </div>
        </div>
      </div>

      {/* Tabs and Content */}
      <div className="flex gap-16 border-t border-white/20 mx-auto" style={{ width: 'calc(100% - 160px)' }}>
        <div className="flex justify-between items-start mb-6">
          <div className="flex flex-col gap-6 w-44">
            <div className="flex space-x-4">
              <button
                className={`px-1 pt-4 border-t-2 ${activeTab === 'library' ? 'border-white' : 'opacity-50'}`}
                onClick={() => setActiveTab('library')}
              >
                My Library
              </button>
              <button
                className={`px-1 pt-4 ${activeTab === 'learn' ? 'border-white' : 'opacity-50'}`}
                onClick={() => setActiveTab('learn')}
              >
                Learn
              </button>
            </div>
            <div className="opacity-50 text-sm">
              {activeTab === 'library'
                ? 'Manage and view your processed images'
                : 'Explore articles and tutorials about image processing'}
            </div>
          </div>
        </div>

        {error && (
          <div className="text-center text-red-500 mb-4">
            {error}
          </div>
        )}

        <div
          className="grow grid gap-4 pt-4"
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
          }}
        >
          {activeTab === 'library' ? (
            images.length > 0 ? (
              images.map((image, index) => (
                <GalleryItem
                  key={image.id}
                  image={image}
                  onClick={() => handleImageClick(index)}
                />
              ))
            ) : (
              <div className="col-span-full text-center text-gray-400">
                No images found. Start by uploading your first image!
              </div>
            )
          ) : (
            learnItems.length > 0 ? (
              learnItems.map((item) => (
                <div key={item.id} className="bg-[var(--coal-700)] p-4 rounded-lg">
                  <img src={item.thumbnailUrl || '/placeholder.svg'} alt={item.title} className="mb-4 w-full rounded" />
                  <div className="text-white text-lg font-semibold">{item.title}</div>
                  <div className="text-white/50">{item.type}</div>
                </div>
              ))
            ) : (
              <div className="col-span-full text-center text-gray-400">
                No learn items found.
              </div>
            )
          )}
        </div>
      </div>

      {/* ZoomControls Component */}
      <ZoomControls zoomLevel={100} onZoomIn={() => { }} onZoomOut={() => { }} />

      {/* Image Modal */}
      <ImageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        images={images}
        initialIndex={selectedIndex}
        className={`transition-all duration-300 ease-in-out ${isModalOpen ? "opacity-100 scale-100" : "opacity-0 scale-95"
          }`}
      />

    </div>
  );
};

export default LandingPage;
