import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import videoFile from '../Assets/button-video-verlay-01-small.mp4';

// Video background component for the button
const VideoBackground = ({ className }) => (
  <video
    className={`absolute inset-0 w-full h-full object-cover ${className}`}
    src={videoFile}
    autoPlay
    loop
    muted
    playsInline
  />
);

const CustomButton = ({ children, onClick, disabled, type, className, ...props }) => {
  const buttonClass = classNames(
    'w-full py-3 rounded-lg transition-colors duration-300 border relative overflow-hidden', // Removed specific styles here to allow focus on border and background
    {
      'bg-gradient-to-r from-buttonDisabledStart to-buttonDisabledEnd cursor-not-allowed border-white/15': disabled,
      'hover:border-[#d5d5d5] border-white/15': !disabled,
    },
    className
  );

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
      type={type || 'button'}
      {...props}
    >
      {/* Conditionally render the video background if the button is not disabled */}
      {!disabled && <VideoBackground className="rounded-lg" />}

      {/* Content of the button (children) with mix-blend-exclusion */}
      <span className="relative z-10 mix-blend-exclusion">{children}</span>
    </button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default CustomButton;
