//This component will handle the reusable input field for the account section.

import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({ label, value, disabled = false, helperText }) => (
  <div className="space-y-2">
    <label className="text-white text-sm">{label}</label>
    <input
      type="text"
      value={value}
      disabled={disabled}
      className={`w-full h-10 px-3 py-1 bg-white/10 rounded text-15 ${disabled ? 'text-white/50' : 'text-white'}`}
    />
    {helperText && <p className="text-white/50 text-13">{helperText}</p>}
  </div>
);

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

export default InputField;
