// src/contexts/ProfileContext.js

import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { message } from 'antd';

// Create the ProfileContext
export const ProfileContext = createContext();

// Create the ProfileProvider component
export const ProfileProvider = ({ children }) => {
  const [avatar, setAvatar] = useState('/placeholder.svg?height=64&width=64');
  const [loading, setLoading] = useState(true);

  // Function to fetch user avatar
  const fetchUserAvatar = async () => {
    const token = Cookies.get('token');
    if (!token) {
      message.error('User not authenticated');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/profile', {
        method: 'GET',
        headers: { 
          'Authorization': `Bearer ${token}`
        },
      });
      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(errorData || 'Failed to fetch user profile');
      }
      const data = await response.json();
      setAvatar(data.photoURL || '/placeholder.svg?height=64&width=64');
    } catch (error) {
      console.error('Error fetching user avatar:', error);
      message.error(`Failed to load user avatar. ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Fetch user avatar on component mount
  useEffect(() => {
    fetchUserAvatar();
  }, []);

  // Function to update avatar
  const updateAvatar = (newAvatar) => {
    setAvatar(newAvatar);
  };

  // Function to handle logout
  const handleLogout = () => {
    Cookies.remove('token');
    localStorage.removeItem('profileAvatar');
    setAvatar('/placeholder.svg?height=64&width=64');
    message.success('Successfully logged out');
    // Navigation after logout can be handled in the component where handleLogout is called
  };

  return (
    <ProfileContext.Provider value={{ avatar, setAvatar: updateAvatar, loading, handleLogout }}>
      {children}
    </ProfileContext.Provider>
  );
};
