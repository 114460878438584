import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'antd';
import { createPortal } from 'react-dom';
import ToggleSwitch from './ToggleSwitch';
import UpscalerTooltip from './UpscalerTooltip';
import { ReactComponent as InfoIcon } from '../Assets/settings_info.svg';

const ModelDropdown = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = ['Auto', 'Cinematic', 'Painting'];
  const selectedModels = ['Auto', 'Cinematic', 'Painting'];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-1 focus:outline-none"
      >
        <span
          className={`${selectedModels.includes(value) ? 'opacity-50' : 'opacity-100'} text-white transition-opacity duration-300`}
        >
          {value}
        </span>
        <svg 
          viewBox="0 0 24 24" 
          className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-1 bg-sidebarBackground border border-white/10 rounded-lg py-1 w-32 z-10 transition-opacity duration-300 ease-in-out">
          {options.map((option) => (
            <button
              key={option}
              className={`w-full px-3 py-1.5 text-left text-white hover:bg-white/10 hover:text-white transition-colors duration-300`}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              <span
                className={`${selectedModels.includes(option) ? 'opacity-50' : 'opacity-100'} transition-opacity duration-300`}
              >
                {option}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

ModelDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SettingsPanel = ({ settings, onToggle, onChange, isRestoration, collapsePanel }) => {
  const [tooltipContent, setTooltipContent] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedModel, setSelectedModel] = useState('Auto');

  const panelRef = useRef(null);

  const handleMouseEnter = (e, content) => {
    setTooltipContent(content);

    if (panelRef.current) {
      const panelRect = panelRef.current.getBoundingClientRect();
      const iconRect = e.currentTarget.getBoundingClientRect();

      const tooltipWidth = 300;
      const gap = 10;

      let tooltipX = panelRect.left - tooltipWidth - gap;
      if (tooltipX < 0) {
        tooltipX = panelRect.right + gap;
      }

      let tooltipY = iconRect.top + window.scrollY - 8 + iconRect.height / 2;

      if (tooltipY < 10) {
        tooltipY = 10;
      }

      const tooltipHeight = 211;
      if (tooltipY + tooltipHeight > window.innerHeight) {
        tooltipY = window.innerHeight - tooltipHeight - 10;
      }

      setTooltipStyle({
        position: 'fixed',
        left: `${tooltipX}px`,
        top: `${tooltipY}px`,
        zIndex: 1000,
        transition: 'opacity 0.3s ease',
      });

      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (collapsePanel) {
      setIsCollapsed(true);
    }
  }, [collapsePanel]);

  // Helper function to get tooltip content
  const getTooltipContent = (option) => {
    switch (option.label) {
      case "Creativity":
        return "Adjusts the level of AI-generated enhancements. Default setting of 65 provides subtle improvements. Increase for more AI-added details, or decrease to maintain closer resemblance to the original image.";
      case "Composition":
        return "Governs how strictly the upscaled image follows the original's grid and layout. Default setting of 5 provides balanced results. Higher values preserve original object placement and grid, while lower values allow more AI-driven compositional changes.";
      case "HDR":
        return "Enhances contrast and color vibrancy. Particularly effective for landscapes, nature photographs, and outdoor scenes. Also beneficial for architectural images and product photography to increase visual impact.";
      default:
        return "";
    }
  };

  // Helper function to render slider labels
  const renderSliderLabels = (option) => {
    switch (option.label.toLowerCase()) {
      case "creativity":
        return (
          <>
            <span>1</span>
            <span>100</span>
          </>
        );
      case "composition":
        return (
          <>
            <span>Hallucinate</span>
            <span>Strict</span>
          </>
        );
      case "hdr":
        return (
          <>
            <span>1</span>
            <span>10</span>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div
        ref={panelRef}
        className="px-4 py-1.5 bg-sidebarBackground rounded-lg border border-border w-full flex flex-col border-white/10 relative overflow-hidden transition-all duration-500 ease-in-out"
      >
        {/* Header */}
        <div
          className="self-stretch h-11 flex justify-between items-center cursor-pointer"
          onClick={toggleCollapse}
        >
          <div className="text-16 font-medium text-white">
            {isRestoration ? "Restoration Settings" : "Upscaling Settings"}
          </div>
          <div className="flex items-center justify-center h-full">
            <div
              className={`w-2 h-2 rounded-full mr-1 transition-opacity duration-300 ${
                isCollapsed ? "bg-white opacity-30" : "bg-white opacity-100"
              }`}
            />
          </div>
        </div>

        {/* Collapsible Content */}
        <div
          className={`transition-max-height duration-500 ease-in-out ${
            isCollapsed ? "max-h-0 opacity-0" : "max-h-screen opacity-100"
          }`}
        >
          {/* Conditionally show tokens and sliders based on isRestoration flag */}
          {!isRestoration && (
            <>
              {/* Scale Factor */}
              {settings.scaleFactor !== null && (
                <div className="mb-1 mt-2">
                  <div className="flex justify-between space-x-2">
                    {[2, 3, 4].map((factor) => (
                      <button
                        key={factor}
                        className={`flex-1 h-10 rounded-lg font-medium transition-colors duration-300 ${
                          settings.scaleFactor === factor
                            ? "bg-[#d5d5d5] text-[#151515]"
                            : "border border-white/10 text-white hover:bg-[#2C2C2C] hover:text-[#D6D6D6]"
                        }`}
                        onClick={() => onToggle("scaleFactor", factor)}
                      >
                        {factor}x
                      </button>
                    ))}
                  </div>
                </div>
              )}

              <div className="self-stretch h-px opacity-10 bg-white my-4" />

              {/* Optimized for Model Selection */}
              <div className="flex justify-between items-center mb-4 relative">
                <div
                  className="flex items-center space-x-2"
                  onMouseEnter={(e) =>
                    handleMouseEnter(
                      e,
                      "Controls the selection of the AI model. Auto is optimal for most images. Choose specific models like Cinematic or Painting for tailored results. We regularly add new models, so check back for more options."
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                  onFocus={(e) =>
                    handleMouseEnter(
                      e,
                      "Controls the selection of the AI model. Auto is optimal for most images. Choose specific models like Cinematic or Painting for tailored results. We regularly add new models, so check back for more options."
                    )
                  }
                  onBlur={handleMouseLeave}
                  tabIndex={0}
                >
                  <InfoIcon
                    className="w-4 h-4 text-white opacity-80 cursor-pointer hover:opacity-100 transition-opacity duration-200"
                    aria-label="Optimized for info"
                  />
                  <span className="text-16 text-white">Optimized for</span>
                </div>
                <ModelDropdown
                  value={selectedModel}
                  onChange={(value) => {
                    setSelectedModel(value);
                    if (settings.onModelChange) {
                      settings.onModelChange(value);
                    }
                  }}
                />
              </div>

              {/* Options with ToggleSwitch and Sliders */}
              {settings.options.map((option) => (
                <div key={option.label} className="mb-4 relative">
                  <div className="flex justify-between items-center">
                    <div
                      className="flex items-center space-x-2"
                      onMouseEnter={(e) =>
                        handleMouseEnter(e, getTooltipContent(option))
                      }
                      onMouseLeave={handleMouseLeave}
                      onFocus={(e) =>
                        handleMouseEnter(e, getTooltipContent(option))
                      }
                      onBlur={handleMouseLeave}
                      tabIndex={0}
                    >
                      <InfoIcon
                        className="w-4 h-4 text-white opacity-80 cursor-pointer hover:opacity-100 transition-opacity duration-200"
                        aria-label={`${option.label} info`}
                      />
                      <span className="text-16 text-white">{option.label}</span>
                    </div>

                    <ToggleSwitch
                      enabled={option.enabled}
                      onToggle={(checked) => onToggle(option.key, checked)}
                      variant="settings"
                    />
                  </div>

                  {/* Slider Container with Transition */}
                  <div
                    className={`transition-all duration-500 ease-in-out overflow-hidden ${
                      option.enabled
                        ? "max-h-96 opacity-100 translate-y-0"
                        : "max-h-0 opacity-0 -translate-y-4"
                    }`}
                  >
                    <div className="mt-3 px-4">
                      <Slider
                        min={option.min}
                        max={option.max}
                        step={option.step}
                        value={option.value}
                        onChange={(val) => onChange(option.key, val)}
                        className="w-full"
                        trackStyle={{
                          backgroundColor: '#d6d6d6',
                          height: '4px',
                          transition: 'background-color 0.3s ease, height 0.3s ease',
                        }}
                        railStyle={{
                          backgroundColor: '#4b5563',
                          height: '4px',
                          transition: 'background-color 0.3s ease, height 0.3s ease',
                        }}
                        handleStyle={{
                          borderColor: '#d6d6d6',
                          height: 16,
                          width: 16,
                          transition: 'border-color 0.3s ease, transform 0.3s ease',
                        }}
                      />
                    </div>
                    <div className="mt-1 px-4 flex justify-between text-14 opacity-40">
                      {renderSliderLabels(option)}
                    </div>
                  </div>
                </div>
              ))}

              <div className="self-stretch h-px opacity-10 bg-white mb-3 mt-1" />
            </>
          )}

          {/* Tokens Information */}
          {settings.tokensAvailable !== null && settings.tokensRequired && (
            <>
              <div className="flex justify-between mb-2 text-14 opacity-50">
                <span>Tokens You Have</span>
                <span>{settings.tokensAvailable}</span>
              </div>
              <div className="flex justify-between mb-2 text-14 opacity-50">
                <span>
                  {isRestoration ? "Tokens to Restore" : "Tokens to Upscale"}
                </span>
                <span>{settings.tokensRequired}</span>
              </div>
            </>
          )}

          {/* Action Button */}
          <div className="mt-4 mb-3">
            {settings.actionButton}
          </div>
        </div>
      </div>

      {/* Tooltip */}
      {showTooltip &&
        createPortal(
          <UpscalerTooltip text={tooltipContent} style={tooltipStyle} />,
          document.body
        )}
    </>
  );
};

SettingsPanel.propTypes = {
  settings: PropTypes.shape({
    scaleFactor: PropTypes.number,
    outputResolution: PropTypes.string,
    tokensAvailable: PropTypes.number,
    tokensRequired: PropTypes.number,
    onModelChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        value: PropTypes.number,
      })
    ).isRequired,
    actionButton: PropTypes.node.isRequired,
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isRestoration: PropTypes.bool,
  collapsePanel: PropTypes.bool,
};

SettingsPanel.defaultProps = {
  isRestoration: false,
  collapsePanel: false,
};

export default SettingsPanel;
